import { type AircraftEngineDto } from '@flyward/assets/models'
import { NumberCellWithLabel, NumberDisplayType, TextCellWithLabel, formatDate } from '@flyward/platform'

interface IPRTechnicalReadOnlyTabProps {
  component: AircraftEngineDto
  csnAtLastPR?: number
  dateAtLastPR?: string
  lastPrDateAtContractDelivery?: string
  tsnAtLastPR?: number
}

const PRTechnicalReadOnlyTab = ({
  component,
  csnAtLastPR = undefined,
  dateAtLastPR = undefined,
  lastPrDateAtContractDelivery = undefined,
  tsnAtLastPR = undefined,
}: IPRTechnicalReadOnlyTabProps) => {
  return (
    <div className="flex flex-col gap-y-2">
      <div className="flex gap-x-6">
        <NumberCellWithLabel
          className="basis-1/6"
          label="TSN"
          info={component.assetComponentUtilizationSnapshot.totalHoursSinceNew}
          displayType={NumberDisplayType.Integer}
        />
        <NumberCellWithLabel
          className="basis-1/6"
          label="CSN"
          info={component.assetComponentUtilizationSnapshot.cyclesSinceNew}
          displayType={NumberDisplayType.Integer}
        />
        <NumberCellWithLabel className="basis-1/6" label="TSN @ last PR" info={tsnAtLastPR} displayType={NumberDisplayType.Integer} />
        <NumberCellWithLabel className="basis-1/6" label="CSN @ last PR" info={csnAtLastPR} displayType={NumberDisplayType.Integer} />
        <TextCellWithLabel className="basis-1/6" label="Date - last PR" info={formatDate(dateAtLastPR)} />
        <div className="basis-1/6"></div>
      </div>
      <div className="flex gap-x-6">
        <NumberCellWithLabel
          className="basis-1/6"
          label="TSLPR"
          info={component.assetComponentUtilizationSnapshot.hoursSinceEvent}
          displayType={NumberDisplayType.Integer}
        />
        <NumberCellWithLabel
          className="basis-1/6"
          label="CSLPR"
          info={component.assetComponentUtilizationSnapshot.cyclesSinceEvent}
          displayType={NumberDisplayType.Integer}
        />
        <TextCellWithLabel className="basis-1/6" label="Last PR date @ contract delivery" info={formatDate(lastPrDateAtContractDelivery)} />
        <div className="mr-12 basis-3/6"></div>
      </div>
    </div>
  )
}

export { PRTechnicalReadOnlyTab }
export type { IPRTechnicalReadOnlyTabProps }
