import { EditableInputCellWithLabelWithLabel, ErrorBoundary, InputType, TextCellWithLabel } from '@flyward/platform'
import { type Control } from 'react-hook-form'
import { type EngineProgramDto } from '../../../models'
import { BasicAssumptionsEditable } from '../BasicAssumptionsEditable'
import { InflationEditable } from '../InflationEditable'
import { MaintenanceProgramDetailsArea } from '../MaintenanceProgramDetailsArea'
import { DerateRatiosEditableTable } from './DerateRatio/DerateRatiosEditableTable'
import { EngineLifeLimitedPartsEditableTable } from './LLPs/EngineLifeLimitedPartsEditableTable'
import { EnginePerformanceRestorationEditableTable } from './EPR/EnginePerformanceRestorationEditableTable'
import { EnvironmentalImpactsEditableTable } from './EnvImpact/EnvironmentalImpactsEditableTab'
import { FlightHoursFlightCyclesRatioEditableTable } from './FHFCRatio/FlightHoursFlightCyclesRatioEditable'
import { StubLifeEditable } from './StubLife/StubLifeEditable'

interface IEngineMaintenanceProgramEditableProps {
  engineProgram: EngineProgramDto | undefined
  formControl: Control<EngineProgramDto, unknown>
}

export const EngineMaintenanceProgramEditable = ({ engineProgram, formControl }: IEngineMaintenanceProgramEditableProps) => {
  return (
    <ErrorBoundary>
      <MaintenanceProgramDetailsArea title="Engine">
        <div className="flex gap-6">
          <EditableInputCellWithLabelWithLabel className="basis-1/6" label="Manufacturer" formControl={formControl} fieldName={`manufacturer`} />
          <EditableInputCellWithLabelWithLabel
            className="basis-1/6"
            label="Engine Model"
            formControl={formControl}
            fieldName={`compatibleComponentModels`}
          />
          <EditableInputCellWithLabelWithLabel
            inputType={InputType.NaturalNumber}
            className="basis-1/6"
            label="Base year"
            formControl={formControl}
            fieldName={`kbReferenceYear`}
          />
        </div>
        <EnginePerformanceRestorationEditableTable eprCheck={engineProgram?.eprCheck} formControl={formControl} />
        <div className="py-2">
          <TextCellWithLabel className="basis-1/6" label="Event type" info={'LLP replacement'} />
        </div>
        <EngineLifeLimitedPartsEditableTable llpChecks={engineProgram?.llpChecks} engineProgram={engineProgram} formControl={formControl} />
      </MaintenanceProgramDetailsArea>
      <MaintenanceProgramDetailsArea title="Inflation">
        <InflationEditable formControl={formControl} percentageFieldName={`inflation.percentage`} />
      </MaintenanceProgramDetailsArea>
      <MaintenanceProgramDetailsArea title="FH/FC Ratio">
        <FlightHoursFlightCyclesRatioEditableTable
          formControl={formControl}
          flightHoursFlightCyclesRatios={engineProgram?.flightHoursFlightCyclesRatios}
        />
      </MaintenanceProgramDetailsArea>
      <MaintenanceProgramDetailsArea title="Derate Ratio">
        <DerateRatiosEditableTable formControl={formControl} derateRatios={engineProgram?.derateRatios} />
      </MaintenanceProgramDetailsArea>
      <MaintenanceProgramDetailsArea title="Environmental Impact">
        <EnvironmentalImpactsEditableTable formControl={formControl} environmentalImpacts={engineProgram?.environmentalImpacts} />
      </MaintenanceProgramDetailsArea>
      <MaintenanceProgramDetailsArea title="Basic Assumptions">
        <BasicAssumptionsEditable
          formControl={formControl}
          downtimeFieldPath={'basicAssumptions.checkDowntimeMonths'}
          delayFieldPath={'basicAssumptions.monthsDelayInPaymentOnClaims'}
        />
      </MaintenanceProgramDetailsArea>
      <MaintenanceProgramDetailsArea title="Stub Life">
        <StubLifeEditable formControl={formControl} percentageFieldName={`stubLife.intervalStart`} />
      </MaintenanceProgramDetailsArea>
    </ErrorBoundary>
  )
}
