import { ComponentMinimumReDeliveryConditionDtoSchema } from '@flyward/platform'
import { ComponentContractDtoSchema, type ComponentContractDto } from '../../contract/ComponentContractDto'
import { EscalationDtoSchema, type EscalationDto } from '../../contract/EscalationDto'
import { LandingGearEndOfLeaseContractDtoSchema, type LandingGearEndOfLeaseContract } from './landingGearEndOfLeaseContract'
import { LandingGearMaintenanceReserveContractSchema, type LandingGearMaintenanceReserveContract } from './landingGearMaintenanceReserve'
import { type LandingGearMinimumReDeliveryCondition } from './landingGearMinimumReDeliveryCondition'
import { z } from 'zod'

export interface LandingGearContract extends ComponentContractDto {
  escalations: EscalationDto
  landingGearMaintenanceReserveContract: LandingGearMaintenanceReserveContract
  landingGearEndOfLeaseContract: LandingGearEndOfLeaseContract
  minimumReDeliveryConditions: LandingGearMinimumReDeliveryCondition[]
}

export const LandingGearContractSchema = ComponentContractDtoSchema.extend({
  escalations: EscalationDtoSchema.optional().nullable(),
  landingGearMaintenanceReserveContract: LandingGearMaintenanceReserveContractSchema.optional().nullable(),
  landingGearEndOfLeaseContract: LandingGearEndOfLeaseContractDtoSchema.optional().nullable(),
  minimumReDeliveryConditions: z.array(ComponentMinimumReDeliveryConditionDtoSchema).optional().nullable(),
})
