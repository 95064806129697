import { EndOfLeaseEditableRates, RedeliveryConditionsEditable } from '@flyward/assets/components/AircraftComponents/common'
import { type ComponentMaintenanceReserveRate, type LandingGearDto } from '@flyward/assets/models'
import {
  CheckTypeDisplay,
  EditableBooleanCellWithLabelWithLabel,
  EditableInputCellWithLabelWithLabel,
  InputType,
  MaintenanceReserveCollectionTypeDisplay,
  TextCellWithLabel,
  UtilizationUnitsDisplay,
} from '@flyward/platform'
import { isNil } from 'lodash'
import { type Control } from 'react-hook-form'

interface ILGContractualEditableTabProps {
  component: LandingGearDto
  formControl: Control<LandingGearDto, unknown>
}

export const LGContractualEditableTab = ({ component, formControl }: ILGContractualEditableTabProps) => {
  const maintenanceReserve = component.landingGearContract.landingGearMaintenanceReserveContract
  const rates = [maintenanceReserve.landingGearMaintenanceReserveRate]

  const minimumRedeliveryConditions = component.landingGearContract.minimumReDeliveryConditions

  const Rates = rates.map((rate: ComponentMaintenanceReserveRate) => {
    if (isNil(rate)) return null
    return (
      <div key={rate.checkType} className="flex w-full gap-x-6 gap-y-4">
        <EditableBooleanCellWithLabelWithLabel
          className="basis-1/6"
          label="MR Active"
          formControl={formControl}
          // disabled={
          //   isNil(component.landingGearContract.landingGearMaintenanceReserveContract.landingGearMaintenanceReserveRate) ||
          //   isEmpty(component.landingGearContract.landingGearMaintenanceReserveContract.landingGearMaintenanceReserveRate)
          // }
          fieldName={`landingGearContract.landingGearMaintenanceReserveContract.isMaintenanceReserveActive`}
        />
        <EditableInputCellWithLabelWithLabel
          inputType={InputType.Decimal}
          className="basis-1/6"
          label={`${CheckTypeDisplay(rate.checkType)} MR rate`}
          formControl={formControl}
          fieldName={`landingGearContract.landingGearMaintenanceReserveContract.landingGearMaintenanceReserveRate.rateAmount`}
          preventInfo={!maintenanceReserve.isMaintenanceReserveActive}
        />
        <TextCellWithLabel
          className="basis-1/6"
          label={`${CheckTypeDisplay(rate.checkType)} rate type`}
          info={UtilizationUnitsDisplay(rate.utilizationUnit)}
          preventInfo={!maintenanceReserve.isMaintenanceReserveActive}
        />
        <EditableInputCellWithLabelWithLabel
          inputType={InputType.NaturalNumber}
          className="basis-1/6"
          label={`${CheckTypeDisplay(rate.checkType)} rate year`}
          formControl={formControl}
          fieldName={`landingGearContract.landingGearMaintenanceReserveContract.landingGearMaintenanceReserveRate.ratesYear`}
          preventInfo={!maintenanceReserve.isMaintenanceReserveActive}
        />
        <TextCellWithLabel
          className="basis-1/6"
          label={`${CheckTypeDisplay(rate.checkType)} MR collection type`}
          info={MaintenanceReserveCollectionTypeDisplay(rate.maintenanceReserveCollectionType)}
          preventInfo={!maintenanceReserve.isMaintenanceReserveActive}
        />
        <EditableInputCellWithLabelWithLabel
          inputType={InputType.NaturalNumber}
          className="basis-1/6"
          label={`Current ${CheckTypeDisplay(rate.checkType)} MR fund`}
          formControl={formControl}
          fieldName={`landingGearContract.landingGearMaintenanceReserveContract.landingGearMaintenanceReserveRate.currentFund`}
          preventInfo={!maintenanceReserve.isMaintenanceReserveActive}
        />
      </div>
    )
  })

  return (
    <div className="flex w-full flex-col gap-y-2">
      <div className="flex w-full flex-col gap-x-6 gap-y-2">{Rates}</div>
      <div className="flex w-full gap-x-1">
        <EditableInputCellWithLabelWithLabel
          inputType={InputType.Percentage}
          className="basis-1/6"
          label="MR Escalation"
          formControl={formControl}
          fieldName={`landingGearContract.escalations.maintenanceReserveAgreedEscalationPercentage`}
          preventInfo={!maintenanceReserve.isMaintenanceReserveActive}
        />
        <div className="ml-28 basis-5/6" />
      </div>
      <div className="flex gap-x-6">
        <EditableInputCellWithLabelWithLabel
          inputType={InputType.Decimal}
          className="basis-1/6"
          label="TSN @ Contract Delivery"
          formControl={formControl}
          fieldName={`landingGearContract.componentContractDeliverySnapshot.totalHoursSinceNewAtContractDelivery`}
          preventInfo={!maintenanceReserve.isMaintenanceReserveActive}
        />
        <EditableInputCellWithLabelWithLabel
          inputType={InputType.Decimal}
          className="basis-1/6"
          label="CSN @ Contract Delivery"
          formControl={formControl}
          fieldName={`landingGearContract.componentContractDeliverySnapshot.cyclesSinceNewAtContractDelivery`}
          preventInfo={!maintenanceReserve.isMaintenanceReserveActive}
        />
        <RedeliveryConditionsEditable
          label="Redelivery Conditions"
          fieldPath="landingGearContract.minimumReDeliveryConditions"
          formControl={formControl}
          conditions={minimumRedeliveryConditions}
        />
        <div className="mr-12 basis-2/6" />
      </div>
      <div className="flex gap-x-6">
        <EditableBooleanCellWithLabelWithLabel
          // disabled={
          //   isNil(component.landingGearContract.landingGearEndOfLeaseContract.landingGearEndOfLeaseRate) ||
          //   isEmpty(component.landingGearContract.landingGearEndOfLeaseContract.landingGearEndOfLeaseRate)
          // }
          className="basis-1/6"
          label="EOL Applicable"
          formControl={formControl}
          fieldName={`landingGearContract.landingGearEndOfLeaseContract.isEOLApplicable`}
        />
        <EndOfLeaseEditableRates
          fieldPath="landingGearContract.landingGearEndOfLeaseContract.landingGearEndOfLeaseRate"
          formControl={formControl}
          isArrayPath={false}
          rates={[component.landingGearContract.landingGearEndOfLeaseContract?.landingGearEndOfLeaseRate]}
          isEolApplicable={component.landingGearContract?.landingGearEndOfLeaseContract?.isEOLApplicable}
        />
        <div className="mr-18 basis-4/6" />
      </div>
    </div>
  )
}
