import {
  type AssetType,
  CheckType,
  cn,
  ComponentType,
  Icon,
  IconVariant,
  type MonthlyStatistics,
  type AssetComponentsMonthlyStatistics,
  Size,
  UtilizationUnits,
  yearMonthFormatShort,
  type GroupedComponentMonthlyStatistics,
  ComponentTabs,
  Tooltip,
} from '@flyward/platform'
import { format } from 'date-fns'
import { useMemo, useState } from 'react'
import { SummaryTable } from '../SummaryTable'
import { CustomStatisticsTable } from '../CustomStatisticsTable'
import { isEmpty } from 'lodash'
import { type IMaintenanceScheduleDisplay } from '@flyward/forecasts/models/entities/MaintenanceSchedule'

interface IStatisticsTableContainerProps {
  assetId: string
  assetType: AssetType
  assetMonthlyStatistics: MonthlyStatistics[]
  reportComponentsMonthlyStatistics: AssetComponentsMonthlyStatistics
  startDate: string
  endDate: string
  isFullPage?: boolean
  allEvents: IMaintenanceScheduleDisplay[]
}

export const StatisticsTableContainer = ({
  assetId,
  assetType,
  assetMonthlyStatistics,
  reportComponentsMonthlyStatistics,
  startDate,
  isFullPage = false,
  endDate,
  allEvents,
}: IStatisticsTableContainerProps) => {
  const tabContentClassName = `
  ${isFullPage ? 'max-h-[calc(100vh-11rem)]' : 'max-h-[calc(100vh-20rem)]'}
  flex
  gap-x-6
  gap-y-2
  scrollbar-thumb-slate-500
  scrollbar
  scrollbar-thumb-rounded-full
  scrollbar-track-rounded-full
  scrollbar-track-transparent
  scrollbar-w-8`
  const summaryTableClassName = `${tabContentClassName} overflow-y-auto overflow-x-hidden`
  const statisticsTablesClassName = `${tabContentClassName}`

  const [sortSummaryByDateAsc, setSortSummaryByDateAsc] = useState<boolean>(true)

  const [currentTab, setCurrentTab] = useState<string>('')
  const onTabChanged = (activeTab: string) => {
    setCurrentTab(activeTab)
  }

  const sortedAssetMonthlyStatistics = useMemo(
    () =>
      assetMonthlyStatistics.slice().sort((a, b) => {
        const yearDiff = sortSummaryByDateAsc ? a.yearMonth.year - b.yearMonth.year : b.yearMonth.year - a.yearMonth.year
        if (yearDiff !== 0) {
          return yearDiff
        } else {
          return sortSummaryByDateAsc ? a.yearMonth.month - b.yearMonth.month : b.yearMonth.month - a.yearMonth.month
        }
      }),
    [sortSummaryByDateAsc, assetMonthlyStatistics],
  )

  // intentional combination of checkType and componentType to determine total row
  const tableTotal: GroupedComponentMonthlyStatistics = {
    forecastedCheck: {
      checkType: CheckType.LandingGearOverhaul,
      componentSerialNumber: 'Total',
      componentType: ComponentType.AuxiliaryPowerUnit,
      componentId: `table-total`,
      componentModel: '',
      kbProgramId: '',

      utilizationUnit: UtilizationUnits.FlightHours,
      kbReferenceYear: 0,
      componentModule: 'FAN',
    },
    monthlyStatistics: reportComponentsMonthlyStatistics.assetMonthlyStatistics,
    subComponents: [],
  }

  const groupedComponentsMonthlyStatistics: GroupedComponentMonthlyStatistics[] = [...reportComponentsMonthlyStatistics.groupedComponentStatistics]
  groupedComponentsMonthlyStatistics.push(tableTotal)

  const tabs = {
    Summary: {
      content: (
        <div className={summaryTableClassName}>
          <SummaryTable
            assetMonthlyStatistics={sortedAssetMonthlyStatistics}
            groupedComponentsMonthlyStatistics={reportComponentsMonthlyStatistics.groupedComponentStatistics}
            assetType={assetType}
            allEvents={allEvents}
            componentTotalStatisticsAtEvent={reportComponentsMonthlyStatistics.componentTotalStatisticsAtEvent}
          />
        </div>
      ),
      tabContent: (
        <div
          className="pointer-events-auto flex w-full	cursor-pointer justify-end"
          // onClick={(e) => {
          //   e.stopPropagation()
          //   if (currentTab !== 'Summary') {
          //     return
          //   }
          //   setSortSummaryByDateAsc((prevValue) => !prevValue)
          // }}
        >
          Summary{' '}
          {sortSummaryByDateAsc ? (
            <Icon variant={IconVariant.ArrowDown} size={Size.Small} />
          ) : (
            <Icon variant={IconVariant.ArrowUp} size={Size.Small} />
          )}
        </div>
      ),
      isControlledActive: currentTab === 'Summary',
      onActiveTabClick: () => {
        setSortSummaryByDateAsc((prevValue) => !prevValue)
      },
    },
    'CF In': {
      content: (
        <div className={statisticsTablesClassName}>
          <CustomStatisticsTable
            componentsMonthlyStatistics={groupedComponentsMonthlyStatistics}
            propertyName="maintenanceReserveCashFlowIn"
            assetMonthlyStatistics={sortedAssetMonthlyStatistics}
          />
        </div>
      ),
    },
    'CF Out': {
      content: (
        <div className={statisticsTablesClassName}>
          <CustomStatisticsTable
            componentsMonthlyStatistics={groupedComponentsMonthlyStatistics}
            propertyName="maintenanceReserveCashFlowOut"
            assetMonthlyStatistics={sortedAssetMonthlyStatistics}
          />
        </div>
      ),
    },
    'MR Balance': {
      content: (
        <div className={statisticsTablesClassName}>
          <CustomStatisticsTable
            componentsMonthlyStatistics={groupedComponentsMonthlyStatistics}
            propertyName="maintenanceReserveBalance"
            assetMonthlyStatistics={sortedAssetMonthlyStatistics}
          />
        </div>
      ),
    },
    'Dollar Maint. Burn': {
      content: (
        <div className={statisticsTablesClassName}>
          <CustomStatisticsTable
            componentsMonthlyStatistics={groupedComponentsMonthlyStatistics}
            propertyName="dollarMaintenanceBurn"
            assetMonthlyStatistics={sortedAssetMonthlyStatistics}
          />
        </div>
      ),
    },
    'Cumulative Utilisation': {
      content: (
        <div className={statisticsTablesClassName}>
          <CustomStatisticsTable
            componentsMonthlyStatistics={groupedComponentsMonthlyStatistics}
            propertyName="forecastedMileage"
            assetMonthlyStatistics={sortedAssetMonthlyStatistics}
          />
        </div>
      ),
      tabContent: (
        <div className="flex">
          {currentTab === 'Summary' ? (
            <Tooltip content="Cumulative Utilisation" tooltipContent="Click to see utilisation for each component" />
          ) : (
            'Cumulative Utilisation'
          )}
        </div>
      ),
    },
    'End of Lease': {
      content: (
        <div className={statisticsTablesClassName}>
          <CustomStatisticsTable
            componentsMonthlyStatistics={groupedComponentsMonthlyStatistics}
            propertyName="endOfLease"
            assetMonthlyStatistics={sortedAssetMonthlyStatistics}
          />
        </div>
      ),
    },
    'Full Life Shortfall': {
      content: (
        <div className={statisticsTablesClassName}>
          <CustomStatisticsTable
            componentsMonthlyStatistics={groupedComponentsMonthlyStatistics}
            propertyName="fullLifeShortfall"
            assetMonthlyStatistics={sortedAssetMonthlyStatistics}
          />
        </div>
      ),
    },
    'Lessor Contribution': {
      content: (
        <div className={statisticsTablesClassName}>
          <CustomStatisticsTable
            componentsMonthlyStatistics={groupedComponentsMonthlyStatistics}
            propertyName="lessorContribution"
            assetMonthlyStatistics={sortedAssetMonthlyStatistics}
          />
        </div>
      ),
    },
  }

  return (
    <>
      <div className={cn('relative flex h-full w-full overflow-hidden border-0')} key={assetId}>
        <ComponentTabs
          tabs={tabs}
          controlledActiveTab={currentTab}
          onTabChanged={onTabChanged}
          tabTriggerHeight="h-13"
          tabTriggerLabelHeight="h-11"
          headerCellClassName={'basis-1/9 !text-wrap p-0 text-right	text-sm text-text-2'}
          columnClassName="text-right justify-end"
        />
      </div>
      <p className="mt-2 pl-4 text-left text-xs text-primary-dark-1">
        From <span className="font-semibold">{format(new Date(startDate), yearMonthFormatShort)}</span> To{' '}
        {!isEmpty(endDate) && <span className="font-semibold">{format(new Date(endDate), yearMonthFormatShort)}</span>}
      </p>
    </>
  )
}
