import { useAssetComponentsForms } from '@flyward/assets/context'
import { PRTechnicalEditableTab } from './PRTechnicalEditableTab'
import { PRTechnicalReadOnlyTab } from './PRTechnicalReadOnlyTab'
import { type AircraftEngineDto } from '@flyward/assets/models'
import { type UseFormSetValue, type Control } from 'react-hook-form'

interface IPRTechnicalTabProps {
  setFormValue: UseFormSetValue<AircraftEngineDto>
  component: AircraftEngineDto
  formControl: Control<AircraftEngineDto, unknown>
  csnAtLastPR?: number
  dateAtLastPR?: string
  lastPrDateAtContractDelivery?: string
  tsnAtLastPR?: number
}

export const PRTechnicalTab = ({
  setFormValue,
  component,
  formControl,
  csnAtLastPR = undefined,
  dateAtLastPR = undefined,
  lastPrDateAtContractDelivery = undefined,
  tsnAtLastPR = undefined,
}: IPRTechnicalTabProps) => {
  const { isInEditMode } = useAssetComponentsForms()

  return (
    <>
      {isInEditMode ? (
        <PRTechnicalEditableTab
          component={component}
          formControl={formControl}
          setFormValue={setFormValue}
          lastPrDateAtContractDelivery={lastPrDateAtContractDelivery}
        />
      ) : (
        <PRTechnicalReadOnlyTab
          component={component}
          csnAtLastPR={csnAtLastPR}
          dateAtLastPR={dateAtLastPR}
          lastPrDateAtContractDelivery={lastPrDateAtContractDelivery}
          tsnAtLastPR={tsnAtLastPR}
        />
      )}
    </>
  )
}
