import { type AircraftEngineDto, type ContractualFlightHoursToFlightCyclesPerformanceRestorationReserveDto } from '@flyward/assets/models'
import { useAssetComponentsForms } from '@flyward/assets/context/AssetComponentsFormsContext'
import { ContractualReserveEditableTab } from './ContractualReserveEditableTab'
import { ContractualReserveReadOnlyTab } from './ContractualReserveReadOnlyTab'
import { type Control, type FormState, type UseFormRegister, type UseFormSetValue } from 'react-hook-form'

interface IContractualReserveTabProps {
  performanceRestorationReserves: ContractualFlightHoursToFlightCyclesPerformanceRestorationReserveDto[]
  registerFormField: UseFormRegister<AircraftEngineDto>
  setFormValue: UseFormSetValue<AircraftEngineDto>
  formControl: Control<AircraftEngineDto, unknown>
  formState: FormState<AircraftEngineDto>
}

const ContractualReserveTab = ({
  performanceRestorationReserves,
  registerFormField,
  setFormValue,
  formControl,
  formState,
}: IContractualReserveTabProps) => {
  const { isInEditMode } = useAssetComponentsForms()

  return (
    <>
      {isInEditMode ? (
        <ContractualReserveEditableTab
          performanceRestorationReserves={performanceRestorationReserves}
          registerFormField={registerFormField}
          setFormValue={setFormValue}
          formControl={formControl}
          formState={formState}
        />
      ) : (
        <ContractualReserveReadOnlyTab performanceRestorationReserves={performanceRestorationReserves} />
      )}
    </>
  )
}

export { ContractualReserveTab }
export type { IContractualReserveTabProps }
