import { AssetInformation, type AssetSearchDTO } from '@flyward/assets'
import { ReportItemStatus } from '@flyward/forecasts/models/enums'
import { APIRoutes, axiosInstance, ErrorBoundary, FlyToType, formatAxiosErrorMessage, IconVariant } from '@flyward/platform'
import { type FlyForwardParametersDTO } from '@flyward/platform/models/DTOs/FlyForwardParametersDTO'
import {
  flyForwardDefaultParametersApiSliceUtil,
  setReportAssetsWithInitialParameters,
  setSelectedAssetId,
  type IFlyForwardDefaultParametersQueryResponse,
  type IReportAsset,
} from '@flyward/platform/store'
import { useAppDispatch, useAppSelector } from '@flyward/platform/store/configureHooks'
import {
  selectFilteredAssetsData,
  selectSelectedAssetId,
  selectUnsortedReportAssets,
} from '@flyward/platform/store/slices/features/flyForward/flyForwardSelectors'
import { isNil } from 'lodash'
import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { AssetsSearch } from './AssetsSearch'
import { SelectedAssetsList } from './SelectedAssetsList'
import { AxiosError } from 'axios'
import { toast, ToastVariant } from '@flyward/platform/components/_shadcn'

const FlyForwardAssetSelection = () => {
  const [searchParams] = useSearchParams()

  const filteredAssetsData = useAppSelector(selectFilteredAssetsData)

  const reportAssets = useAppSelector(selectUnsortedReportAssets)

  const availableAssetsData = filteredAssetsData?.items ?? []

  const dispatch = useAppDispatch()
  const globalSelectedAssetId = useAppSelector(selectSelectedAssetId)

  const initiallySelectedAsset = availableAssetsData?.find((asset: AssetSearchDTO) => asset.assetId === globalSelectedAssetId?.id)

  const maxIndex = (assetId: string) => {
    const maxIndexOfAsset = reportAssets
      .filter((r) => r.assetId.id === assetId)
      .reduce((max, item) => {
        return Math.max(max, item.assetId.index)
      }, -1)

    return maxIndexOfAsset + 1
  }

  const getInitialFlyForwardParameters = (
    asset: AssetSearchDTO,
    assetFlyForwardDefaultParameters: FlyForwardParametersDTO,
  ): FlyForwardParametersDTO => {
    const averageMonthlyFlightHours = Number(assetFlyForwardDefaultParameters.averageMonthlyFlightHours)
    const averageMonthlyFlightCycles = Number(assetFlyForwardDefaultParameters.averageMonthlyFlightCycles)
    const averageMonthlyAPUHours = Number(assetFlyForwardDefaultParameters.averageMonthlyAPUHours)

    return {
      averageMonthlyFlightHours,
      averageMonthlyFlightCycles,
      averageMonthlyAPUHours,
      assetType: asset.assetType,
      engineLifeLimitedPartsBuildGoal: Number(assetFlyForwardDefaultParameters.engineLifeLimitedPartsBuildGoal),
      endDate: assetFlyForwardDefaultParameters.endDate ?? '',
      flyToType: FlyToType.Redelivery,
      hoursToCyclesRatio: averageMonthlyFlightHours / averageMonthlyFlightCycles,
      masterComponentsRates: assetFlyForwardDefaultParameters.masterComponentsRates,
      assetKbChecks: assetFlyForwardDefaultParameters.assetKbChecks,
      assetId: asset.assetId,
      aircraftDetails: assetFlyForwardDefaultParameters.aircraftDetails,
      standaloneEngineDetails: assetFlyForwardDefaultParameters.standaloneEngineDetails,
    }
  }

  const handleAssetSelectionAndGetData = async (newSelectedAssetId: string) => {
    const newSelectedAsset = availableAssetsData.find((t) => t.assetId === newSelectedAssetId)

    if (isNil(newSelectedAsset)) {
      return
    }

    // the reason we cannot use RTK query is because RTK query creates
    // an observable behind and if we use
    // even useLazyQuerySubscription, it will cause rerenders

    try {
      const assetFlyForwardDefaultParametersRequest = await axiosInstance.get<IFlyForwardDefaultParametersQueryResponse>(
        APIRoutes.AssetsModule.AssetsController.GetFlyForwardDefaultParameters(newSelectedAsset.assetId),
      )

      const assetFlyForwardDefaultParameters = assetFlyForwardDefaultParametersRequest.data

      flyForwardDefaultParametersApiSliceUtil.updateQueryData(
        'getFlyForwardDefaultParameters',
        {
          assetId: newSelectedAsset.assetId,
        },
        (_draft) => (_draft = assetFlyForwardDefaultParameters),
      )

      const reportAssetWithInitialParams: IReportAsset = {
        assetId: { id: newSelectedAsset.assetId, index: maxIndex(newSelectedAsset.assetId) },
        assetSerialNumber: newSelectedAsset.serialNumber,
        assetComponentsMonthlyStatistics: undefined,
        eventSchedule: [],
        flyForwardParameters: getInitialFlyForwardParameters(newSelectedAsset, assetFlyForwardDefaultParameters),
        initialFlyForwardParameters: getInitialFlyForwardParameters(newSelectedAsset, assetFlyForwardDefaultParameters),
        reportItemId: undefined,
        reportItemStatus: ReportItemStatus.Initial,
      }

      dispatch(setSelectedAssetId({ assetId: { id: newSelectedAsset.assetId, index: maxIndex(newSelectedAsset.assetId) } }))

      dispatch(
        setReportAssetsWithInitialParameters({
          newReportAssets: [reportAssetWithInitialParams],
        }),
      )
    } catch (error) {
      if (error instanceof AxiosError) {
        toast({
          variant: ToastVariant.Destructive,
          description: formatAxiosErrorMessage(JSON.stringify(error?.response)),
          icon: IconVariant.Error,
        })
      }
    }
  }

  useEffect(() => {
    const addInitialSelection = () => {
      searchParams.getAll('assetIds').forEach((preSelectedAssetId: string) => {
        handleAssetSelectionAndGetData(preSelectedAssetId)
      })
    }

    addInitialSelection()
    // intended to have it as [] so that we only run it once
  }, [])

  return (
    <ErrorBoundary>
      <div className="m-0 flex w-full flex-col gap-y-4 p-0">
        <div className="flex w-73 flex-row items-center justify-between">
          <AssetsSearch handleAssetSelectionAndGetData={handleAssetSelectionAndGetData} />
        </div>
        <div className="flex flex-col items-start justify-center gap-2">
          <SelectedAssetsList />
        </div>
        {!isNil(initiallySelectedAsset) && <AssetInformation asset={initiallySelectedAsset} showSerialNumber />}
      </div>
    </ErrorBoundary>
  )
}

export { FlyForwardAssetSelection }
