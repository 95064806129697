import { useChangeStateMutation, useGetUsersQuery, useResendEmailMutation } from '@flyward/appIdentity/store'
import { PageContainer } from '@flyward/main-app/layout'
import {
  ActionDialog,
  Button,
  ButtonVariant,
  cn,
  Combobox,
  ErrorBoundary,
  fromIsoToFormatDate,
  type GetAllUsersDto,
  type IButtonProps,
  IconVariant,
  type ISelectOption,
  RoleDisplay,
  Size,
  TextCellWithLabel,
  useHeaderContent,
  UserState,
  UserStateDisplay,
} from '@flyward/platform'
import { isNil } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { AddEditUser } from './AddEditUser'
import { toast, ToastVariant } from '@flyward/platform/components/_shadcn'
import { useUserAuthenticated } from '@flyward/appIdentity'

enum Action {
  None,
  ResendInvitation,
  ChangeStatus,
}

export const UserManagement = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isEditMode, setIsEditMode] = useState<boolean>(false)
  const [selectedUser, setSelectedUser] = useState<GetAllUsersDto | null>(null)
  const [isOpenConfirm, setIsOpenConfirm] = useState<boolean>(false)
  const [confirmQuestion, setConfirmQuestion] = useState<string>('')
  const [userId, setUserId] = useState<string>('')
  const [option, setOption] = useState<Action>(Action.None)
  const { setTitle, setButtons } = useHeaderContent()
  const { data: users } = useGetUsersQuery()
  const [changeState] = useChangeStateMutation()
  const [resendInvitation] = useResendEmailMutation()
  const { loggedUser } = useUserAuthenticated()
  const userStates: ISelectOption[] = [
    { label: UserStateDisplay(UserState.Active), value: UserState.Active.toString() },
    { label: UserStateDisplay(UserState.Inactive), value: UserState.Inactive.toString() },
  ]

  const updateStatus = async () => {
    setConfirmQuestion('Are you sure you want to change the status?')
  }

  const resendInvitationClick = async () => {
    setConfirmQuestion('Are you sure you want to resend the invitation?')
  }

  const handleSave = useCallback(async () => {
    if (option === Action.ResendInvitation) {
      const { error: invitationError } = await resendInvitation(userId)
      if (isNil(invitationError)) {
        toast({
          variant: ToastVariant.Success,
          description: 'Invitation resent successfully!',
          icon: IconVariant.Success,
        })
      } else {
        toast({
          variant: ToastVariant.Destructive,
          description: 'Something went wrong while trying to sent the verification email!',
          icon: IconVariant.Error,
        })
      }
    } else if (option === Action.ChangeStatus) {
      const { error: invitationError } = await changeState(userId)
      if (isNil(invitationError)) {
        toast({
          variant: ToastVariant.Success,
          description: 'Status changed successfully!',
          icon: IconVariant.Success,
        })
      } else {
        toast({
          variant: ToastVariant.Destructive,
          description: 'Something went wrong while trying to change the status!',
          icon: IconVariant.Error,
        })
      }
    }
  }, [changeState, option, resendInvitation, userId])

  useEffect(() => {
    const userManagementButtons: IButtonProps[] = [
      {
        variant: ButtonVariant.Primary,
        size: Size.Medium,
        label: 'Add user',
        leftIcon: IconVariant.Add,
        onClick: () => {
          setSelectedUser(null)
          setIsEditMode(false)
          setIsOpen(true)
        },
      },
    ]

    setTitle('User Management')
    setButtons(userManagementButtons)

    return () => {
      setTitle('')
      setButtons([])
    }
  }, [setButtons, setTitle])

  return (
    <PageContainer>
      <div className="h-screen w-full overflow-y-scroll bg-black-0 p-3">
        {users?.map((user, index) => {
          return (
            <ErrorBoundary key={user.userId}>
              <div
                className={cn('h-17 flex w-full p-2 text-sm', index !== users.length - 1 && 'border-b-2 border-primary-light-2')}
                key={user.userId}
              >
                <TextCellWithLabel label="First name" info={user.firstName} className="h-full basis-1/8" labelClassName="mb-1" />
                <TextCellWithLabel label="Last name" info={user.lastName} className="h-full basis-1/8" labelClassName="mb-1" />
                <TextCellWithLabel label="Email" info={user.email} className="h-full basis-2/8" labelClassName="mb-1" />
                <TextCellWithLabel label="Role" info={RoleDisplay(user.role)} className="h-full basis-1/8" labelClassName="mb-1" />
                <div className="h-full basis-1/8">
                  <div className="inline-flex h-10 items-center justify-center gap-1">
                    <div className="flex items-center justify-center p-1">
                      <div className={cn('h-3 w-3 rounded-full ', user.state === UserState.Active ? 'bg-success' : 'bg-header-cell')}></div>
                    </div>
                    <div className="text-1">
                      {user.state !== UserState.PendingInvitation && !isNil(loggedUser) && loggedUser.id !== user.userId ? (
                        <Combobox
                          items={userStates}
                          id="userState"
                          className="w-32"
                          hasOutline={false}
                          hasSearchInput={false}
                          selectedValues={[user.state.toString()]}
                          setSelectedValues={() => {
                            updateStatus()
                            setUserId(user.userId)
                            setOption(Action.ChangeStatus)
                            setIsOpenConfirm(true)
                          }}
                        />
                      ) : (
                        <p className="w-32 pl-3">{UserStateDisplay(user.state)}</p>
                      )}
                    </div>
                  </div>
                </div>
                <TextCellWithLabel
                  label="Profile added"
                  info={fromIsoToFormatDate(user.createdAt.toString())}
                  className="h-full basis-1/8"
                  labelClassName="mb-1"
                />
                <div className="h-full basis-1/8 text-right">
                  <div className="inline-flex h-10 items-center justify-center gap-1">
                    {!isNil(loggedUser) && loggedUser?.id !== user.userId && (
                      <Button
                        variant={ButtonVariant.Ghost}
                        leftIcon={IconVariant.Create}
                        onClick={() => {
                          setSelectedUser(user)
                          setIsOpen(true)
                          setIsEditMode(true)
                        }}
                      />
                    )}
                    {!user.isEmailVerified && (
                      <Button
                        variant={ButtonVariant.Ghost}
                        leftIcon={IconVariant.Email}
                        onClick={() => {
                          resendInvitationClick()
                          setUserId(user.userId)
                          setOption(Action.ResendInvitation)
                          setIsOpenConfirm(true)
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </ErrorBoundary>
          )
        })}
      </div>
      <AddEditUser isEditMode={isEditMode} isOpen={isOpen} setIsOpen={setIsOpen} user={selectedUser} />
      <ActionDialog
        isValid={true}
        confirmBtnLabel="Confirm"
        key={`save-confirm`}
        onConfirm={handleSave}
        isOpen={isOpenConfirm}
        setIsOpen={setIsOpenConfirm}
        dialogContent={<p>{confirmQuestion}</p>}
      />
    </PageContainer>
  )
}
