import { type Control } from 'react-hook-form'
import { type AirframeProgramDto, type CheckDto } from '../../../../models'
import { type ChecksDisplay, ChecksEditableTable } from '../../ChecksEditableTable'

interface IAirframeChecksEditableTableProps {
  checks?: CheckDto[]
  formControl: Control<AirframeProgramDto, unknown>
}

export const AirframeChecksEditableTable = ({ checks = [], formControl }: IAirframeChecksEditableTableProps) => {
  const mappedData = checks.map((check: CheckDto): ChecksDisplay => {
    return {
      checkId: check.id,
      checkType: check.checkType,
      utilizationUnit: check.utilizationUnit,
      utilization: check.matureLimit,
      baseCost: check.baseCost,
    }
  })
  return <ChecksEditableTable checks={mappedData} formControl={formControl} />
}
