import { validateNullableNaturalNumber } from '@flyward/platform/helpers/inputValidators'
import { z } from 'zod'

export interface ComponentContractDeliverySnapshot {
  componentContractId: string
  totalHoursSinceNewAtContractDelivery: number
  cyclesSinceNewAtContractDelivery: number
  totalHoursSinceLastEventAtContractDelivery: number | null
  cyclesSinceLastEventAtContractDelivery: number | null
}

export const ComponentContractDeliverySnapshotSchema = z.object({
  componentContractId: z.any().optional().nullable(),
  totalHoursSinceNewAtContractDelivery: validateNullableNaturalNumber,
  cyclesSinceNewAtContractDelivery: validateNullableNaturalNumber,
  totalHoursSinceLastEventAtContractDelivery: validateNullableNaturalNumber,
  cyclesSinceLastEventAtContractDelivery: validateNullableNaturalNumber,
})
