import { CheckTypeDisplay, isArrayEmptyOrNull, NumberCellWithLabel, NumberDisplayType, TextCellWithLabel } from '@flyward/platform'
import { Fragment } from 'react'
import { type ComponentEndOfLeaseRate } from '../../../../../models'
import { cloneDeep } from 'lodash'

interface IEndOfLeaseReadonlyRatesProps {
  rates: ComponentEndOfLeaseRate[]
  isEolApplicable: boolean
}

export const EndOfLeaseReadonlyRates = ({
  rates,
  isEolApplicable,
}: IEndOfLeaseReadonlyRatesProps): React.ReactElement<IEndOfLeaseReadonlyRatesProps> => {
  if (isArrayEmptyOrNull(rates)) {
    return (
      <Fragment>
        <TextCellWithLabel className="basis-1/6" label={`EOL Rate`} preventInfo={!isEolApplicable} />
      </Fragment>
    )
  }

  cloneDeep(rates).sort((a, b) => Number(a.checkType) - Number(b.checkType))

  const eolRates = rates.map((eolRate: ComponentEndOfLeaseRate, index) => {
    return (
      <Fragment key={`${index} - ${eolRate?.checkType}`}>
        <NumberCellWithLabel
          className="basis-1/6"
          label={`EOL Rates ${CheckTypeDisplay(eolRate?.checkType)}`}
          info={eolRate?.rateAmount}
          preventInfo={!isEolApplicable}
          displayType={NumberDisplayType.Currency}
        />
      </Fragment>
    )
  })

  return <>{eolRates}</>
}
