import { useState } from 'react'
import { Button, ButtonVariant } from '../Button/Button'
import { IconVariant } from '../Icon/IconVariant'
import { cn } from '../_shadcn/utils/utils'
import { ActionDialog } from '../ActionDialog/ActionDialog'
import { ToastVariant, useToast } from '../_shadcn'

interface IButtonWithDialog {
  id: string
  name: string
  onConfirm: (assetId: string) => Promise<boolean>
  type: string
  dataPermissionId: string
  actionLabel: string
  icon: IconVariant
  isExpanded?: boolean
}

export const ButtonWithDialog = ({ id, name, onConfirm, type, dataPermissionId, actionLabel, icon, isExpanded = false }: IButtonWithDialog) => {
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false)
  const { toast } = useToast()
  return (
    <>
      <Button
        variant={ButtonVariant.Ghost}
        leftIcon={icon}
        className={cn(`-mr-52 justify-end ${isExpanded && 'text-black-0'}`)}
        onClick={() => {
          setIsConfirmModalOpen(true)
        }}
      />

      <ActionDialog
        data-permission-element-id={dataPermissionId}
        confirmBtnLabel={actionLabel}
        dialogHeader={`${actionLabel} ${type}`}
        key={`action-${type}-${id}`}
        onConfirm={async () => {
          const isSuccess = await onConfirm(id)
          if (isSuccess) {
            toast({
              variant: ToastVariant.Success,
              description: `${type} ${actionLabel} was successful!`,
              icon: IconVariant.Success,
            })
          } else {
            toast({
              variant: ToastVariant.Destructive,
              description: `Something went wrong while trying to ${actionLabel} the ${type}!`,
              icon: IconVariant.Error,
            })
          }
        }}
        onCancel={() => {
          setIsConfirmModalOpen(false)
        }}
        isOpen={isConfirmModalOpen}
        setIsOpen={setIsConfirmModalOpen}
        dialogContent={
          <p>
            Are you sure you want to {actionLabel.toLocaleLowerCase()} the {type} <strong>{name}</strong> ?
          </p>
        }
        isValid
      />
    </>
  )
}
