/* eslint-disable @typescript-eslint/no-misused-promises */
import { type LandingGearProgramDto, LandingGearProgramDtoSchema } from '@flyward/knowledgeBase'
import { useGetLandingGearMaintenanceProgramDetailsQuery, useUpdateLandingGearMaintenanceProgramDetailsMutation } from '@flyward/knowledgeBase/store'
import { PageContainer } from '@flyward/main-app/layout'
import { CnForm, ErrorBoundary, formatAxiosErrorMessage, IconVariant, useHeaderContent, useNavigationState, useSpinnerState } from '@flyward/platform'
import { toast, ToastVariant } from '@flyward/platform/components/_shadcn'
import { zodResolver } from '@hookform/resolvers/zod'
import { isEmpty, isNil } from 'lodash'
import { useEffect, useRef, useState } from 'react'
import { type SubmitHandler, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { LandingGearMaintenanceProgramPageBody } from './LandingGearMaintenanceProgramPageBody'
import { LandingGearMaintenanceProgramPageHeader } from './LandingGearMaintenanceProgramPageHeader'

export const LandingGearMaintenanceProgramPage = () => {
  const { id } = useParams()
  const submitRef = useRef<HTMLInputElement>(null)
  const [updateLandingGear] = useUpdateLandingGearMaintenanceProgramDetailsMutation()

  const { showSpinner, hideSpinner } = useSpinnerState()

  const { setTitle, setInputs, setButtons, setHasBackButton } = useHeaderContent()
  const { toggleVisibility } = useNavigationState()

  const [isInEditMode, setIsInEditMode] = useState(false)

  const { data: landingGearMaintenanceProgram, isLoading } = useGetLandingGearMaintenanceProgramDetailsQuery(
    { programId: id! },
    {
      skip: isNil(id) || isEmpty(id),
    },
  )

  isLoading ? showSpinner() : hideSpinner()

  useEffect(() => {
    toggleVisibility()
    setTitle('Landing Gear Program')
    setHasBackButton(true)

    return () => {
      setTitle('')
      setButtons([])
      setHasBackButton(false)
      setInputs([])
      toggleVisibility()
    }
  }, [setButtons, setTitle, setInputs, setHasBackButton, toggleVisibility])

  const form = useForm<LandingGearProgramDto>({
    defaultValues: landingGearMaintenanceProgram,
    values: landingGearMaintenanceProgram,
    resolver: zodResolver(LandingGearProgramDtoSchema),
    mode: 'all',
    reValidateMode: 'onChange',
  })

  const {
    handleSubmit: handleLandingGearUpdate,
    formState,
    getValues: getLandingGearFormValues,
    control: formControl,
    trigger: triggerValidations,
  } = form

  const landingGearFormValues: LandingGearProgramDto = getLandingGearFormValues()

  const { isDirty, isValid } = formState

  if (isDirty && !isValid) {
    console.log('KB Landing Gear formState.errors', formState.errors)
  }

  useEffect(() => {
    triggerValidations()
  }, [isValid, triggerValidations])

  const onUpdateLandingGear: SubmitHandler<LandingGearProgramDto> = async (data) => {
    const result = await updateLandingGear({ programId: id!, lg: data })
    if (!isNil(result.error)) {
      toast({
        variant: ToastVariant.Destructive,
        description: formatAxiosErrorMessage(result.error?.message),
        icon: IconVariant.Error,
      })
    } else {
      toast({
        variant: ToastVariant.Success,
        description: 'Landing Gear updated successfully',
        icon: IconVariant.Success,
      })
    }
  }

  const submitForm = () => {
    submitRef.current?.click()
  }

  return (
    <ErrorBoundary>
      <CnForm {...form}>
        <form onSubmit={handleLandingGearUpdate(onUpdateLandingGear)}>
          <LandingGearMaintenanceProgramPageHeader
            isInEditMode={isInEditMode}
            setIsInEditMode={setIsInEditMode}
            isDirty={isDirty}
            isValid={isValid}
            submitForm={submitForm}
          />
          <PageContainer>
            <LandingGearMaintenanceProgramPageBody
              landingGearMaintenanceProgram={landingGearFormValues}
              isInEditMode={isInEditMode}
              formControl={formControl}
            />
          </PageContainer>
          <input type="submit" className="hidden" ref={submitRef} />
        </form>
      </CnForm>
    </ErrorBoundary>
  )
}
