import { z } from 'zod'

/**
 * Cloned from from API: Assets.Core.Domain.DTOs.Components.Abstract.EndOfLease.ComponentEndOfLeaseContractDto
 */
export interface ComponentEndOfLeaseContract {
  isEOLApplicable: boolean
}

export const ComponentEndOfLeaseContractSchema = z.object({
  isEOLApplicable: z.any().nullable(),
})
