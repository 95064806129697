import { EndOfLeaseEditableRates, RedeliveryConditionsEditable } from '@flyward/assets/components/AircraftComponents/common'
import { type AircraftEngineDto } from '@flyward/assets/models'
import {
  EditableBooleanCellWithLabelWithLabel,
  EditableInputCellWithLabelWithLabel,
  InputType,
  TextCellWithLabel,
  UtilizationUnitsDisplay,
} from '@flyward/platform'
import { isNil } from 'lodash'
import { type Control } from 'react-hook-form'

export interface IContractualEditableTabProps {
  component: AircraftEngineDto
  formControl: Control<AircraftEngineDto, unknown>
}

export const ContractualEditableTab = ({ component, formControl }: IContractualEditableTabProps) => {
  const maintenanceReserve = component.engineContract.engineUnitMaintenanceReserveContract
  return (
    <div className="flex flex-col gap-y-2">
      <div className="flex gap-x-6">
        <EditableInputCellWithLabelWithLabel
          inputType={InputType.NaturalNumber}
          className="basis-1/6"
          label="Build Standard"
          formControl={formControl}
          fieldName={`engineContract.llPsContractualBuildStandard`}
        />
        <EditableBooleanCellWithLabelWithLabel
          className="basis-1/6"
          label="MR Active"
          formControl={formControl}
          // disabled={
          //   isNil(component.engineContract.engineUnitMaintenanceReserveContract.engineLLPsTotalMaintenanceReserveRate) ||
          //   isEmpty(component.engineContract.engineUnitMaintenanceReserveContract.engineLLPsTotalMaintenanceReserveRate)
          // }
          fieldName={`engineContract.engineUnitMaintenanceReserveContract.isMaintenanceReserveActive`}
        />
        <EditableInputCellWithLabelWithLabel
          inputType={InputType.NaturalNumber}
          className="basis-1/6"
          label="MR rates Year"
          formControl={formControl}
          fieldName={`engineContract.engineUnitMaintenanceReserveContract.enginePerformanceRestorationMaintenanceRate.ratesYear`}
          preventInfo={!maintenanceReserve.isMaintenanceReserveActive}
        />
        <EditableBooleanCellWithLabelWithLabel
          className="basis-1/6"
          label="MR Flag First Run"
          formControl={formControl}
          // disabled={
          //   isNil(component.engineContract.engineUnitMaintenanceReserveContract.engineLLPsTotalMaintenanceReserveRate) ||
          //   isEmpty(component.engineContract.engineUnitMaintenanceReserveContract.engineLLPsTotalMaintenanceReserveRate)
          // }
          fieldName={`engineContract.engineUnitMaintenanceReserveContract.isFirstRun`}
        />
        <EditableInputCellWithLabelWithLabel
          inputType={InputType.Percentage}
          className="basis-1/6"
          label="PR MR Agreed Escalation"
          formControl={formControl}
          fieldName={`engineContract.escalations.eprEscalation.maintenanceReserveAgreedEscalationPercentage`}
          preventInfo={!maintenanceReserve.isMaintenanceReserveActive}
        />
        <EditableInputCellWithLabelWithLabel
          inputType={InputType.Percentage}
          className="basis-1/6"
          label="LLP MR Agreed Escalation"
          formControl={formControl}
          fieldName={`engineContract.escalations.llPsEscalation.maintenanceReserveAgreedEscalationPercentage`}
          preventInfo={!maintenanceReserve.isMaintenanceReserveActive}
        />
      </div>
      <div className="flex gap-x-6">
        <EditableInputCellWithLabelWithLabel
          inputType={InputType.Decimal}
          className="basis-1/6"
          label="PR MR rate"
          formControl={formControl}
          fieldName={`engineContract.engineUnitMaintenanceReserveContract.enginePerformanceRestorationMaintenanceRate.rateAmount`}
          preventInfo={!maintenanceReserve.isMaintenanceReserveActive}
        />
        <TextCellWithLabel
          className="basis-1/6"
          label="PR MR rate type"
          info={UtilizationUnitsDisplay(
            component.engineContract.engineUnitMaintenanceReserveContract.enginePerformanceRestorationMaintenanceRate?.utilizationUnit,
          )}
          preventInfo={!maintenanceReserve.isMaintenanceReserveActive}
        />
        <EditableInputCellWithLabelWithLabel
          inputType={InputType.Decimal}
          className="basis-1/6"
          label="LLP MR rate"
          formControl={formControl}
          fieldName={`engineContract.engineUnitMaintenanceReserveContract.engineLLPsTotalMaintenanceReserveRate.rateAmount`}
          preventInfo={!maintenanceReserve.isMaintenanceReserveActive}
        />
        <TextCellWithLabel
          className="basis-1/6"
          label="PR MR rate type"
          info={UtilizationUnitsDisplay(
            component.engineContract.engineUnitMaintenanceReserveContract.engineLLPsTotalMaintenanceReserveRate?.utilizationUnit,
          )}
          preventInfo={!maintenanceReserve.isMaintenanceReserveActive}
        />
        <EditableInputCellWithLabelWithLabel
          inputType={InputType.NaturalNumber}
          className="basis-1/6"
          label="Current PR MR fund"
          formControl={formControl}
          fieldName={`engineContract.engineUnitMaintenanceReserveContract.enginePerformanceRestorationMaintenanceRate.currentFund`}
          preventInfo={!maintenanceReserve.isMaintenanceReserveActive}
        />
        <EditableInputCellWithLabelWithLabel
          inputType={InputType.NaturalNumber}
          className="basis-1/6"
          label="Current LLP MR fund"
          formControl={formControl}
          fieldName={`engineContract.engineUnitMaintenanceReserveContract.engineLLPsTotalMaintenanceReserveRate.currentFund`}
          preventInfo={!maintenanceReserve.isMaintenanceReserveActive}
        />
      </div>
      <div className="flex gap-x-6">
        <EditableInputCellWithLabelWithLabel
          inputType={InputType.NaturalNumber}
          className="basis-1/6"
          label="TSN @ contract delivery"
          formControl={formControl}
          fieldName={`engineContract.componentContractDeliverySnapshot.totalHoursSinceNewAtContractDelivery`}
        />
        <EditableInputCellWithLabelWithLabel
          inputType={InputType.NaturalNumber}
          className="basis-1/6"
          label="CSN @ contract delivery"
          formControl={formControl}
          fieldName={`engineContract.componentContractDeliverySnapshot.cyclesSinceNewAtContractDelivery`}
        />
        <RedeliveryConditionsEditable
          label="Return Conditions PR"
          fieldPath="engineContract.minimumReDeliveryConditions.eprMinimumReDeliveryConditions"
          formControl={formControl}
          conditions={component.engineContract.minimumReDeliveryConditions.eprMinimumReDeliveryConditions}
          className="mr-6"
        />
        <RedeliveryConditionsEditable
          label="Return Conditions LLP"
          fieldPath="engineContract.minimumReDeliveryConditions.llpMinimumReDeliveryConditions"
          formControl={formControl}
          conditions={component.engineContract.minimumReDeliveryConditions.llpMinimumReDeliveryConditions}
          className="mr-6"
        />
      </div>
      <div className="flex gap-x-6">
        <EditableBooleanCellWithLabelWithLabel
          className="basis-1/6"
          label="EOL Active"
          formControl={formControl}
          // disabled={
          //   isNil(component.engineContract.engineUnitEndOfLeaseContract.enginePerformanceRestorationEndOfLeaseRate) ||
          //   isEmpty(component.engineContract.engineUnitEndOfLeaseContract.enginePerformanceRestorationEndOfLeaseRate)
          // }
          fieldName={`engineContract.engineUnitEndOfLeaseContract.isEOLApplicable`}
        />
        <EndOfLeaseEditableRates
          fieldPath="engineContract.engineUnitEndOfLeaseContract.enginePerformanceRestorationEndOfLeaseRate"
          formControl={formControl}
          isArrayPath={false}
          rates={
            !isNil(component.engineContract.engineUnitEndOfLeaseContract?.enginePerformanceRestorationEndOfLeaseRate)
              ? [component.engineContract.engineUnitEndOfLeaseContract?.enginePerformanceRestorationEndOfLeaseRate]
              : []
          }
          isEolApplicable={component.engineContract.engineUnitEndOfLeaseContract?.isEOLApplicable}
        />
        {/* <EndOfLeaseEditableRates
          fieldPath="engineContract.engineUnitEndOfLeaseContract.engineLLPsTotalEndOfLeaseRate"
          formControl={formControl}
          isArrayPath={false}
          rates={
            !isNil(component.engineContract.engineUnitEndOfLeaseContract?.engineLLPsTotalEndOfLeaseRate)
              ? [component.engineContract.engineUnitEndOfLeaseContract?.engineLLPsTotalEndOfLeaseRate]
              : []
          }
          isEolApplicable={component.engineContract.engineUnitEndOfLeaseContract?.isEOLApplicable}
        /> */}
        <div className="ml-6 basis-4/6" />
      </div>
    </div>
  )
}
