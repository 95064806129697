import { type UtilizationUnits, UtilizationUnitsDisplay, type TableColumn, EditableInputCellWithLabelWithLabel, InputType } from '@flyward/platform'
import { MaintenanceProgramDetailsTable } from '../../MaintenanceProgramDetailsTable'
import { type EprCheckDto } from '../../../../models'
import { type FieldValues, type Control, type Path } from 'react-hook-form'

interface EprCheckEditableDisplay {
  programId: string
  eventType: string
  unit?: UtilizationUnits
  firstRunLimit?: number
  matureLimit?: number
  baseCost?: number
}

interface IEnginePerformanceRestorationEditableTableProps<TFormData extends FieldValues> {
  eprCheck: EprCheckDto | undefined
  formControl: Control<TFormData, unknown>
}
const eprTableColumns = <TFormData extends FieldValues>(formControl: Control<TFormData, unknown>): Array<TableColumn<EprCheckEditableDisplay>> => [
  {
    accessor: 'programId',
    isIdField: true,
    title: '',
    isHidden: true,
  },
  {
    accessor: 'eventType',
    title: 'Event Type',
    isIdField: true,
  },
  {
    accessor: 'unit',
    title: 'Unit',
    valueGetter: (value) => UtilizationUnitsDisplay(value as UtilizationUnits),
  },
  {
    accessor: 'firstRunLimit',
    title: 'First Run Limit',
    customCell: () => {
      const filedName = `eprCheck.firstRunLimit` as Path<TFormData>
      return (
        <div className="w-32 min-w-32 text-xs">
          <EditableInputCellWithLabelWithLabel inputType={InputType.NaturalNumber} formControl={formControl} fieldName={filedName} />
        </div>
      )
    },
  },
  {
    accessor: 'matureLimit',
    title: 'Mature Limit',
    customCell: () => {
      const filedName = `eprCheck.matureLimit` as Path<TFormData>
      return (
        <div className="w-32 min-w-32 text-xs">
          <EditableInputCellWithLabelWithLabel inputType={InputType.NaturalNumber} formControl={formControl} fieldName={filedName} />
        </div>
      )
    },
  },
  {
    accessor: 'baseCost',
    title: 'Cost',
    customCell: () => {
      const filedName = `eprCheck.baseCost` as Path<TFormData>
      return (
        <div className="w-32 min-w-32 text-xs">
          <EditableInputCellWithLabelWithLabel inputType={InputType.NaturalNumber} formControl={formControl} fieldName={filedName} />
        </div>
      )
    },
  },
]

export const EnginePerformanceRestorationEditableTable = <TFormData extends FieldValues>({
  eprCheck,
  formControl,
}: IEnginePerformanceRestorationEditableTableProps<TFormData>): React.ReactElement<IEnginePerformanceRestorationEditableTableProps<TFormData>> => {
  const eprTableData: EprCheckEditableDisplay | undefined =
    eprCheck !== undefined
      ? {
          eventType: 'EPR',
          programId: eprCheck?.id,
          unit: eprCheck?.utilizationUnit,
          firstRunLimit: eprCheck?.firstRunLimit,
          matureLimit: eprCheck?.matureLimit,
          baseCost: eprCheck?.baseCost,
        }
      : undefined

  return <MaintenanceProgramDetailsTable columns={eprTableColumns(formControl)} data={[eprTableData]} />
}
