import { AuthorizedElement } from '@flyward/appIdentity'
import { AssetTypeFilter } from '@flyward/assets'
import { useImportAssetMutation } from '@flyward/assets/store'
import {
  ActionDialog,
  ButtonVariant,
  formatAxiosErrorMessage,
  IconVariant,
  Size,
  ToastVariant,
  UploadInput,
  useHeaderContent,
  type AssetType,
  type IButtonProps,
} from '@flyward/platform'
import { toast } from '@flyward/platform/components/_shadcn'
import { useAppSelector } from '@flyward/platform/store/configureHooks'
import { selectSelectedAssetIds } from '@flyward/platform/store/slices/features/fleet/fleetSelectors'
import { isNil } from 'lodash'
import queryString from 'query-string'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

interface IFleetPageHeader {
  isUploadModalOpen: boolean
  setIsUploadModalOpen: (isOpen: boolean) => void
}

export const FleetPageHeader = ({ isUploadModalOpen, setIsUploadModalOpen }: IFleetPageHeader) => {
  const navigate = useNavigate()
  const selectedAssetIds = useAppSelector(selectSelectedAssetIds)
  const { setTitle, setButtons, setCustomElements } = useHeaderContent()
  const [assetType, setAssetType] = useState<AssetType | undefined>(undefined)
  const [file, setFile] = useState<File | null>(null)
  const [importAsset] = useImportAssetMutation()

  const handleSave = useCallback(async () => {
    const { error: importError } = await importAsset({ assetType: assetType!, file: file! })

    if (isNil(importError)) {
      toast({
        variant: ToastVariant.Success,
        description: 'Your asset has been successfully imported!',
        icon: IconVariant.Success,
      })
    } else {
      toast({
        variant: ToastVariant.Destructive,
        description: formatAxiosErrorMessage(importError?.message),
        icon: IconVariant.Error,
      })
    }
  }, [assetType, file, importAsset])

  const handleSuccess = () => {
    setAssetType(undefined)
    setFile(null)
  }

  useEffect(() => {
    const handleCancel = () => {
      setAssetType(undefined)
      setFile(null)
    }

    const buttonProps: IButtonProps[] = [
      {
        variant: ButtonVariant.Primary,
        size: Size.Medium,
        label: 'Fly Forward',
        leftIcon: IconVariant.FlightTakeOff,
        onClick: () => {
          navigate(`/flyforward/?${queryString.stringify({ assetIds: selectedAssetIds })}`)
        },
      },
    ]

    setTitle('Fleet Summary')
    setButtons(buttonProps)

    setCustomElements([
      <AuthorizedElement key="asset-import-key">
        <ActionDialog
          data-permission-element-id="asset-import"
          isValid={!isNil(assetType) && !isNil(file)}
          confirmBtnLabel="Upload"
          dialogHeader="Upload file"
          key="upload-file"
          onConfirm={handleSave}
          onCancel={handleCancel}
          onSuccess={handleSuccess}
          isOpen={isUploadModalOpen}
          setIsOpen={setIsUploadModalOpen}
          dialogContent={
            <>
              <AssetTypeFilter assetType={assetType} setAssetType={setAssetType} allowAllOption={false} id={'import-asset'} />
              <UploadInput setFile={setFile} />
            </>
          }
        />
      </AuthorizedElement>,
    ])

    return () => {
      setTitle('')
      setButtons([])
    }
  }, [selectedAssetIds, navigate, setButtons, setTitle, setCustomElements, handleSave, isUploadModalOpen, setIsUploadModalOpen, assetType, file])

  return <></>
}
