import { type ContractualFlightHoursToFlightCyclesPerformanceRestorationReserveDto } from '@flyward/assets/models'
import { getCoreRowModel, useReactTable } from '@tanstack/react-table'
import { contractualReserveReadOnlyColumns } from './constants'
import { GenericTable } from '@flyward/platform/components/Table/GenericTable'

interface IContractualReserveReadOnlyTabProps {
  performanceRestorationReserves: ContractualFlightHoursToFlightCyclesPerformanceRestorationReserveDto[]
}

const ContractualReserveReadOnlyTab = ({ performanceRestorationReserves }: IContractualReserveReadOnlyTabProps) => {
  const contractualReserveTable = useReactTable<ContractualFlightHoursToFlightCyclesPerformanceRestorationReserveDto>({
    data: performanceRestorationReserves,
    columns: contractualReserveReadOnlyColumns,
    getRowCanExpand: () => true,
    getCoreRowModel: getCoreRowModel(),
    enableMultiRowSelection: false,
  })

  return <GenericTable {...contractualReserveTable} />
}

export { ContractualReserveReadOnlyTab }
export type { IContractualReserveReadOnlyTabProps }
