import {
  EditableInputCellWithLabelWithLabel,
  EditableSelectCellWithLabel,
  EnvironmentalImpactTypeOptions,
  InputType,
  type TableColumn,
} from '@flyward/platform'
import { type EnvironmentalImpact } from '../../../../models'
import { MaintenanceProgramDetailsTable } from '../../MaintenanceProgramDetailsTable'
import { type Path, type Control, type FieldValues } from 'react-hook-form'

interface IEnvironmentalImpactsTableEditableProps<TFormData extends FieldValues> {
  environmentalImpacts: EnvironmentalImpact[] | undefined
  formControl: Control<TFormData, unknown>
}

const tableColumns = <TFormData extends FieldValues>(formControl: Control<TFormData, unknown>): Array<TableColumn<EnvironmentalImpact>> => [
  {
    accessor: 'id',
    isIdField: true,
    title: '',
    isHidden: true,
  },
  {
    accessor: 'impactType',
    title: 'Environment',
    customCell: ({ rowIndex: index }) => {
      const filedName = `environmentalImpacts.${index}.impactType` as Path<TFormData>
      return (
        <div className="w-24 min-w-24 text-xs">
          <EditableSelectCellWithLabel
            className="min-w-24 basis-2/6 whitespace-pre"
            options={EnvironmentalImpactTypeOptions}
            formControl={formControl}
            fieldName={filedName}
          />{' '}
        </div>
      )
    },
  },
  {
    accessor: 'percentage',
    title: 'Percentage',
    customCell: ({ rowIndex: index }) => {
      const filedName = `environmentalImpacts.${index}.percentage` as Path<TFormData>
      return (
        <div className="w-24 min-w-24 text-xs">
          <EditableInputCellWithLabelWithLabel inputType={InputType.Percentage} formControl={formControl} fieldName={filedName} />
        </div>
      )
    },
  },
]

export const EnvironmentalImpactsEditableTable = <TFormData extends FieldValues>({
  environmentalImpacts,
  formControl,
}: IEnvironmentalImpactsTableEditableProps<TFormData>): React.ReactElement<IEnvironmentalImpactsTableEditableProps<TFormData>> => {
  return <MaintenanceProgramDetailsTable columns={tableColumns(formControl)} data={environmentalImpacts} />
}
