import { ComponentEndOfLeaseRateSchema } from '../../contract'
import { ComponentEndOfLeaseContractSchema, type ComponentEndOfLeaseContract } from '../../contract/ComponentEndOfLeaseContract'
import { type LandingGearEndOfLeaseRate } from './LandingGearEndOfLeaseRate'

export interface LandingGearEndOfLeaseContract extends ComponentEndOfLeaseContract {
  landingGearEndOfLeaseRate: LandingGearEndOfLeaseRate
}

export const LandingGearEndOfLeaseContractDtoSchema = ComponentEndOfLeaseContractSchema.extend({
  landingGearEndOfLeaseRate: ComponentEndOfLeaseRateSchema.optional().nullable(),
})
