import { ComponentEndOfLeaseRateSchema, type ComponentEndOfLeaseRate } from '../../contract'
import { ComponentEndOfLeaseContractSchema, type ComponentEndOfLeaseContract } from '../../contract/ComponentEndOfLeaseContract'
import { z } from 'zod'

/**
 * Cloned from API: Assets.Core.Domain.DTOs.Components.Airframe.AirframeEndOfLeaseContractDto
 */
export interface AirframeEndOfLeaseContractDto extends ComponentEndOfLeaseContract {
  airframeEndOfLeaseRates: ComponentEndOfLeaseRate[]
}

export const AirframeEndOfLeaseContractDtoSchema = ComponentEndOfLeaseContractSchema.extend({
  airframeEndOfLeaseRates: z.array(ComponentEndOfLeaseRateSchema),
})
