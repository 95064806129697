import { type AxiosResponse } from 'axios'
import { isNil } from 'lodash'
import React from 'react'
import { Error400Display, type IError400Response } from './Error400Display'

const formatAxiosErrorMessage = (jsonErrorResponse: string | undefined): React.ReactNode => {
  if (isNil(jsonErrorResponse)) {
    return <div>Unknown error</div>
  }

  const axiosErrorResponse: AxiosResponse = JSON.parse(jsonErrorResponse)

  console.error('Axios Error:', axiosErrorResponse)
  if (axiosErrorResponse.status === 400) {
    const errorResponse = axiosErrorResponse.data as IError400Response

    return <Error400Display errors={errorResponse.errors} />
  }

  return <React.Fragment>{JSON.stringify(axiosErrorResponse)}</React.Fragment>
}

export { formatAxiosErrorMessage }
