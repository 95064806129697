import { CheckTypeDisplay, Combobox, Input, InputType, type CheckType } from '@flyward/platform'
import { type CheckTyeRateDTO } from '@flyward/platform/models/DTOs/CheckTyeRateDTO'
import { type FlyForwardParametersDTO } from '@flyward/platform/models/DTOs/FlyForwardParametersDTO'
import { type MasterComponentRatesDTO } from '@flyward/platform/models/DTOs/MasterComponentMRRatesDTO'
import { isNil } from 'lodash'

interface IEditMRRates {
  masterComponentRate: MasterComponentRatesDTO
  flyForwardParameters: FlyForwardParametersDTO
  handleRateChange: (
    originalFlyForwardParameters: FlyForwardParametersDTO,
    masterComponentSerialNumber: string,
    rateType: 'mrRates' | 'eolRates',
    checkType: CheckType,
    field: keyof CheckTyeRateDTO,
    value: number,
  ) => void
  handleRateActive: (
    originalFlyForwardParameters: FlyForwardParametersDTO,
    masterComponentSerialNumber: string,
    activeType: 'eolIsActive' | 'mrIsActive',
    value: boolean,
  ) => void
}

export const EditMRRates = ({ masterComponentRate, flyForwardParameters, handleRateChange, handleRateActive }: IEditMRRates) => {
  const ratesOptions = [
    { value: 'true', label: 'Yes' },
    { value: 'false', label: 'No' },
  ]

  return (
    <div className="flex flex-col gap-y-4">
      <div className="flex gap-x-6">
        <Combobox
          items={ratesOptions}
          itemLabel="label"
          label="MR Active"
          selectedValues={masterComponentRate.mrIsActive ? ['true'] : ['false']}
          setSelectedValues={(values: string[]) => {
            handleRateActive(flyForwardParameters, masterComponentRate.masterComponentSerialNumber, 'mrIsActive', values[0] === 'true')
          }}
          className="!w-65"
          hasSearchInput={false}
        />
        <Combobox
          items={ratesOptions}
          itemLabel="label"
          label="EOL Active"
          selectedValues={masterComponentRate.eolIsActive ? ['true'] : ['false']}
          setSelectedValues={(values: string[]) => {
            handleRateActive(flyForwardParameters, masterComponentRate.masterComponentSerialNumber, 'eolIsActive', values[0] === 'true')
          }}
          className="!w-65"
          hasSearchInput={false}
        />
      </div>

      <div className="flex flex-wrap gap-x-6 gap-y-4">
        {masterComponentRate.mrRates.map((mrRate) => {
          return (
            <Input
              key={`${masterComponentRate.masterComponentSerialNumber}-mr-${mrRate.checkType}-amount`}
              label={`${CheckTypeDisplay(mrRate.checkType)} MR Rate`}
              controlledValue={String(mrRate.rateAmount)}
              setValueAfterValidation={(value) => {
                value !== String(mrRate.rateAmount) &&
                  handleRateChange(
                    flyForwardParameters,
                    masterComponentRate.masterComponentSerialNumber,
                    'mrRates',
                    mrRate.checkType,
                    'rateAmount',
                    parseFloat(value),
                  )
              }}
              type={InputType.Decimal}
              inputClassName="w-65"
              disabled={!masterComponentRate.mrIsActive}
            />
          )
        })}

        {masterComponentRate.mrRates.map((mrRate) => {
          const controlledValue = isNil(mrRate.rateFund) ? undefined : String(Number(mrRate.rateFund).toFixed(0))
          return (
            <Input
              key={`${masterComponentRate.masterComponentSerialNumber}-mr-${mrRate.checkType}-fund`}
              label={`Current ${CheckTypeDisplay(mrRate.checkType)} MR Fund`}
              controlledValue={controlledValue}
              setValueAfterValidation={(value) => {
                value !== String(Number(mrRate.rateFund).toFixed(0)) &&
                  handleRateChange(
                    flyForwardParameters,
                    masterComponentRate.masterComponentSerialNumber,
                    'mrRates',
                    mrRate.checkType,
                    'rateFund',
                    parseInt(value),
                  )
              }}
              type={InputType.NaturalNumber}
              inputClassName="w-65"
              disabled={!masterComponentRate.mrIsActive}
            />
          )
        })}
      </div>
    </div>
  )
}
