/* eslint-disable max-len */
import { isNil } from 'lodash'
import React from 'react'
import { Input, InputVariant } from '../_shadcn'

interface IUploadInput {
  setFile: (file: File | null) => void
}

export const UploadInput = ({ setFile }: IUploadInput) => {
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!isNil(event.target.files) && event.target.files.length > 0) {
      setFile(event.target.files[0])
    }
  }

  return (
    <Input
      type="file"
      accept=".xls,.xlsx"
      onChange={handleFileChange}
      className="border-primary file:rounded-md file:border file:border-solid file:border-primary file:bg-primary file:text-white hover:file:bg-primary"
      variant={InputVariant.File}
      multiple={false}
    />
  )
}

export default UploadInput
