import { ComponentEndOfLeaseContractSchema, type ComponentEndOfLeaseContract } from '../../contract/ComponentEndOfLeaseContract'
import { ComponentEndOfLeaseRateSchema, type ComponentEndOfLeaseRate } from '../../contract/ComponentEndOfLeaseRate'

/**
 * Cloned from from API: Assets.Core.Domain.DTOs.Components.Engine.EngineUnitEndOfLeaseContractDto
 */

export interface EngineUnitEndOfLeaseContractDto extends ComponentEndOfLeaseContract {
  enginePerformanceRestorationEndOfLeaseRate?: ComponentEndOfLeaseRate
  engineLLPsTotalEndOfLeaseRate?: ComponentEndOfLeaseRate
}

export const EngineUnitEndOfLeaseContractSchema = ComponentEndOfLeaseContractSchema.extend({
  enginePerformanceRestorationEndOfLeaseRate: ComponentEndOfLeaseRateSchema.optional().nullable(),
  engineLLPsTotalEndOfLeaseRate: ComponentEndOfLeaseRateSchema.optional().nullable(),
})
