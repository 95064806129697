import { type AirframeProgramDto } from '../../../models'
import { EditableInputCellWithLabelWithLabel, ErrorBoundary, InputType } from '@flyward/platform'
import { type Control } from 'react-hook-form'
import { MaintenanceProgramDetailsArea } from '../MaintenanceProgramDetailsArea'
import { AirframeChecksEditableTable } from './AirframeChecksTable/AirframeChecksEditableTable'
import { InflationEditable } from '../InflationEditable'
import { BasicAssumptionsEditable } from '../BasicAssumptionsEditable'

interface IAirframeMaintenanceProgramEditableProps {
  airframeMaintenanceProgram: AirframeProgramDto | undefined
  formControl: Control<AirframeProgramDto, unknown>
}

export const AirframeMaintenanceProgramEditable = ({ airframeMaintenanceProgram, formControl }: IAirframeMaintenanceProgramEditableProps) => {
  return (
    <ErrorBoundary>
      <MaintenanceProgramDetailsArea title="Airframe">
        <div className="flex gap-6">
          <EditableInputCellWithLabelWithLabel className="basis-1/6" label="Manufacturer" formControl={formControl} fieldName={`manufacturer`} />
          <EditableInputCellWithLabelWithLabel
            className="basis-1/6"
            label="Airframe Model"
            formControl={formControl}
            fieldName={`compatibleComponentModels`}
          />
          <EditableInputCellWithLabelWithLabel
            inputType={InputType.NaturalNumber}
            className="basis-1/6"
            label="Base year"
            formControl={formControl}
            fieldName={`kbReferenceYear`}
          />
        </div>
        <div className="flex gap-6">
          <EditableInputCellWithLabelWithLabel className="basis-1/6" label="Aircraft" formControl={formControl} fieldName={`aircraft`} />
          <EditableInputCellWithLabelWithLabel className="basis-1/6" label="Series" formControl={formControl} fieldName={`series`} />
        </div>
      </MaintenanceProgramDetailsArea>
      <MaintenanceProgramDetailsArea title="Checks">
        <AirframeChecksEditableTable checks={airframeMaintenanceProgram?.checks} formControl={formControl} />
      </MaintenanceProgramDetailsArea>
      <MaintenanceProgramDetailsArea title="Inflation">
        <InflationEditable formControl={formControl} percentageFieldName={`inflation.percentage`} />
      </MaintenanceProgramDetailsArea>
      <MaintenanceProgramDetailsArea title="Basic Assumptions">
        <BasicAssumptionsEditable
          formControl={formControl}
          downtimeFieldPath={'basicAssumptions.checkDowntimeMonths'}
          delayFieldPath={'basicAssumptions.monthsDelayInPaymentOnClaims'}
        />
      </MaintenanceProgramDetailsArea>
    </ErrorBoundary>
  )
}
