import { Button, AssetType, IconVariant, ButtonVariant, Size, TextCellWithLabel } from '@flyward/platform'
import { Label } from '@radix-ui/react-label'

interface IAssetSelectionCell {
  serialNumber: string
  assetType: AssetType
  isExpanded: boolean
  onClick: () => void
}

export const AssetIdentificationCell = ({ serialNumber, assetType, isExpanded, onClick }: IAssetSelectionCell) => {
  return (
    <Label htmlFor={serialNumber} className="ml-4 flex w-56 cursor-pointer items-center gap-x-4">
      <Button
        leftIcon={assetType === AssetType.Aircraft ? IconVariant.PlaneZoomOut : IconVariant.Engine}
        variant={ButtonVariant.Ghost}
        size={Size.Large}
        className={`m-0 h-8 p-0 hover:bg-primary-light-2 ${isExpanded ? 'text-text-4' : 'text-primary'} `}
        onClick={onClick}
      />
      <TextCellWithLabel
        label={assetType === AssetType.Aircraft ? 'MSN' : 'ESN'}
        info={serialNumber}
        labelClassName={` ${isExpanded && 'text-black-20'}`}
        infoClassName={`w-46 truncate ${isExpanded && 'text-text-4'}`}
      />
    </Label>
  )
}
