import { EndOfLeaseEditableRates, RedeliveryConditionsEditable } from '@flyward/assets/components/AircraftComponents/common'
import { type AirframeDto, type ComponentMaintenanceReserveRate } from '@flyward/assets/models'
import {
  CheckTypeDisplay,
  cn,
  EditableBooleanCellWithLabelWithLabel,
  EditableInputCellWithLabelWithLabel,
  InputType,
  isArrayEmptyOrNull,
  MaintenanceReserveCollectionTypeDisplay,
  TextCellWithLabel,
  UtilizationUnitsDisplay,
} from '@flyward/platform'
import { cloneDeep, isNil } from 'lodash'
import { type Control, type FormState, type UseFormSetValue, type UseFormWatch } from 'react-hook-form'

interface IAirframeContractualEditableTabProps {
  airframe: AirframeDto
  formControl: Control<AirframeDto, unknown>
  formState: FormState<AirframeDto>
  watchFields: UseFormWatch<AirframeDto>
  setFormValue: UseFormSetValue<AirframeDto>
}

export const AirframeContractualEditableTab = ({ airframe, formControl }: IAirframeContractualEditableTabProps) => {
  const maintenanceReserve = airframe.airframeContract.airframeMaintenanceReserveContract
  const rates = maintenanceReserve.airframeMaintenanceRates
  const minimumRedeliveryConditions = airframe.airframeContract.minimumReDeliveryConditions

  const Rates = cloneDeep(rates)
    .sort((a, b) => Number(a.checkType) - Number(b.checkType))
    .map((rate: ComponentMaintenanceReserveRate, index) => {
      if (isNil(rate)) return null
      return (
        <div key={rate.checkType} className="flex w-full gap-x-6 gap-y-4">
          <EditableInputCellWithLabelWithLabel
            inputType={InputType.Decimal}
            className="basis-1/6"
            label={`${CheckTypeDisplay(rate.checkType)} MR rate`}
            formControl={formControl}
            fieldName={`airframeContract.airframeMaintenanceReserveContract.airframeMaintenanceRates.${index}.rateAmount`}
            preventInfo={!maintenanceReserve.isMaintenanceReserveActive}
          />
          <TextCellWithLabel
            className="basis-1/6"
            label={`${CheckTypeDisplay(rate.checkType)} rate type`}
            info={UtilizationUnitsDisplay(rate.utilizationUnit)}
            preventInfo={!maintenanceReserve.isMaintenanceReserveActive}
          />
          <EditableInputCellWithLabelWithLabel
            inputType={InputType.NaturalNumber}
            className="basis-1/6"
            label={`${CheckTypeDisplay(rate.checkType)} rate year`}
            formControl={formControl}
            fieldName={`airframeContract.airframeMaintenanceReserveContract.airframeMaintenanceRates.${index}.ratesYear`}
            preventInfo={!maintenanceReserve.isMaintenanceReserveActive}
          />
          <TextCellWithLabel
            className="basis-1/6"
            label={`${CheckTypeDisplay(rate.checkType)} MR collection type`}
            info={MaintenanceReserveCollectionTypeDisplay(rate.maintenanceReserveCollectionType)}
            preventInfo={!maintenanceReserve.isMaintenanceReserveActive}
          />
          <EditableInputCellWithLabelWithLabel
            inputType={InputType.NaturalNumber}
            className="basis-1/6"
            label={`Current ${CheckTypeDisplay(rate.checkType)} MR fund`}
            formControl={formControl}
            fieldName={`airframeContract.airframeMaintenanceReserveContract.airframeMaintenanceRates.${index}.currentFund`}
            preventInfo={!maintenanceReserve.isMaintenanceReserveActive}
          />
          <div className="basis-1/6" />
        </div>
      )
    })

  return (
    <div className="flex w-full flex-col gap-y-2">
      <div className="flex w-full gap-6">
        <EditableBooleanCellWithLabelWithLabel
          className="basis-1/6"
          label="MR Active"
          formControl={formControl}
          disabled={isArrayEmptyOrNull(airframe.airframeContract.airframeMaintenanceReserveContract.airframeMaintenanceRates)}
          fieldName={`airframeContract.airframeMaintenanceReserveContract.isMaintenanceReserveActive`}
        />
        <EditableInputCellWithLabelWithLabel
          inputType={InputType.Percentage}
          className="basis-1/6"
          label="MR Escalation"
          formControl={formControl}
          fieldName={`airframeContract.escalations.maintenanceReserveAgreedEscalationPercentage`}
          preventInfo={!maintenanceReserve.isMaintenanceReserveActive}
        />
        <div className="mr-18 basis-4/6" />
      </div>
      <div className="flex w-full flex-col gap-y-2">{Rates}</div>
      <div className="flex gap-x-6">
        <EditableBooleanCellWithLabelWithLabel
          disabled={isArrayEmptyOrNull(airframe.airframeContract.airframeEndOfLeaseContract?.airframeEndOfLeaseRates)}
          className="basis-1/6"
          label="EOL Applicable"
          formControl={formControl}
          fieldName={`airframeContract.airframeEndOfLeaseContract.isEOLApplicable`}
        />
        <EndOfLeaseEditableRates
          fieldPath="airframeContract.airframeEndOfLeaseContract.airframeEndOfLeaseRates"
          formControl={formControl}
          rates={airframe.airframeContract.airframeEndOfLeaseContract?.airframeEndOfLeaseRates}
          isEolApplicable={airframe.airframeContract?.airframeEndOfLeaseContract?.isEOLApplicable}
        />
        <div
          className={cn(
            // TODO: refactor this, we should not be forced to have flex basis
            `basis-4/6`,
            'mr-18',
          )}
        />
      </div>
      <div className="flex gap-x-6">
        <EditableInputCellWithLabelWithLabel
          inputType={InputType.Decimal}
          className="basis-1/6"
          label="TSN @ Contract Delivery"
          formControl={formControl}
          fieldName={`airframeContract.componentContractDeliverySnapshot.totalHoursSinceNewAtContractDelivery`}
        />
        <EditableInputCellWithLabelWithLabel
          inputType={InputType.Decimal}
          className="basis-1/6"
          label="CSN @ Contract Delivery"
          formControl={formControl}
          fieldName={`airframeContract.componentContractDeliverySnapshot.cyclesSinceNewAtContractDelivery`}
        />
        <RedeliveryConditionsEditable
          label="Redelivery Conditions"
          fieldPath="airframeContract.minimumReDeliveryConditions"
          formControl={formControl}
          conditions={minimumRedeliveryConditions}
        />
        <div className="mr-12 basis-2/6" />
      </div>
    </div>
  )
}
