import { type Control } from 'react-hook-form'
import { type LandingGearProgramDto, type CheckDto } from '../../../../models'
import { type ChecksDisplay, ChecksEditableTable } from '../../ChecksEditableTable'

interface ILandingGearChecksEditableTableProps {
  checks?: CheckDto[]
  formControl: Control<LandingGearProgramDto, unknown>
}

export const LandingGearChecksEditableTable = ({ checks = [], formControl }: ILandingGearChecksEditableTableProps) => {
  const mappedData = checks.map((check: CheckDto): ChecksDisplay => {
    return {
      checkId: check.id,
      checkType: check.checkType,
      utilizationUnit: check.utilizationUnit,
      utilization: check.matureLimit,
      baseCost: check.baseCost,
    }
  })
  return <ChecksEditableTable checks={mappedData} formControl={formControl} isArrayPath={false} />
}
