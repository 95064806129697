import { type AircraftEngineDto, type EngineLifeLimitedPartDto } from '@flyward/assets/models'
import { useAssetComponentsForms } from '@flyward/assets/context/AssetComponentsFormsContext'
import { LLPTechnicalEditableTab } from './LLPTechnicalEditableTab'
import { LLPTechnicalReadOnlyTab } from './LLPTechnicalReadOnlyTab'
import { type Control, type FormState, type UseFormRegister, type UseFormSetValue } from 'react-hook-form'

interface ILLPTechnicalTabProps {
  engineLifeLimitedParts: EngineLifeLimitedPartDto[]
  registerFormField: UseFormRegister<AircraftEngineDto>
  setFormValue: UseFormSetValue<AircraftEngineDto>
  formControl: Control<AircraftEngineDto, unknown>
  formState: FormState<AircraftEngineDto>
}

const LLPTechnicalTab = ({ engineLifeLimitedParts, registerFormField, setFormValue, formControl, formState }: ILLPTechnicalTabProps) => {
  const { isInEditMode } = useAssetComponentsForms()

  return (
    <>
      {isInEditMode ? (
        <LLPTechnicalEditableTab
          engineLifeLimitedParts={engineLifeLimitedParts}
          registerFormField={registerFormField}
          setFormValue={setFormValue}
          formControl={formControl}
          formState={formState}
        />
      ) : (
        <LLPTechnicalReadOnlyTab engineLifeLimitedParts={engineLifeLimitedParts} />
      )}
    </>
  )
}

export { LLPTechnicalTab }
export type { ILLPTechnicalTabProps }
