import { type AircraftEngineDto, type ContractualFlightHoursToFlightCyclesPerformanceRestorationReserveDto } from '@flyward/assets/models'
import { EditableInputCellWithLabelWithLabel, InputType, NumberDisplay, NumberDisplayType, SimpleHeader } from '@flyward/platform'
import { type ColumnDef } from '@tanstack/react-table'
import { type Control } from 'react-hook-form'

const contractualReserveReadOnlyColumns: Array<ColumnDef<ContractualFlightHoursToFlightCyclesPerformanceRestorationReserveDto>> = [
  {
    id: 'ratio',
    accessorKey: 'flightHoursToFlightCyclesRatio',
    header: () => <SimpleHeader title="FH/FC ratio" className="text-semibold m-0 w-full p-1 text-center text-xs text-black-0" />,
    cell: ({ getValue }) => (
      <NumberDisplay className="flex justify-center p-0 text-xs text-text-1" displayType={NumberDisplayType.Decimal} value={getValue()} />
    ),
  },
  {
    id: 'rate',
    accessorKey: 'reserveAmount',
    header: () => <SimpleHeader title="PR MR rate" className="text-semibold m-0 w-full p-1 text-center text-xs text-black-0" />,
    cell: ({ getValue }) => (
      <NumberDisplay className="flex justify-end p-0 text-xs text-text-1" displayType={NumberDisplayType.Currency} value={getValue()} />
    ),
  },
]

const contractualReserveEditableColumns = (
  formControl: Control<AircraftEngineDto, unknown>,
): Array<ColumnDef<ContractualFlightHoursToFlightCyclesPerformanceRestorationReserveDto>> => [
  {
    id: 'ratio',
    accessorKey: 'flightHoursToFlightCyclesRatio',
    header: () => <SimpleHeader title="FH/FC ratio" className="text-semibold m-0 w-full p-1 text-center text-xs text-black-0" />,
    cell: ({ row }) => (
      <div className="w-24 min-w-24 text-xs">
        <EditableInputCellWithLabelWithLabel
          inputType={InputType.Decimal}
          formControl={formControl}
          fieldName={`engineContract.contractualFlightHoursToFlightCyclesPerformanceRestorationReserves.${row.index}.flightHoursToFlightCyclesRatio`}
        />
      </div>
    ),
  },
  {
    id: 'rate',
    accessorKey: 'reserveAmount',
    header: () => <SimpleHeader title="PR MR rate" className="text-semibold m-0 w-full p-1 text-center text-xs text-black-0" />,
    cell: ({ row }) => (
      <div className="w-24 min-w-24 text-xs">
        <EditableInputCellWithLabelWithLabel
          inputType={InputType.NaturalNumber}
          formControl={formControl}
          fieldName={`engineContract.contractualFlightHoursToFlightCyclesPerformanceRestorationReserves.${row.index}.reserveAmount`}
        />
      </div>
    ),
  },
]

export { contractualReserveReadOnlyColumns, contractualReserveEditableColumns }
