import {
  type CheckType,
  CheckTypeDisplay,
  EditableInputCellWithLabelWithLabel,
  InputType,
  type TableColumn,
  type UtilizationUnits,
  UtilizationUnitsDisplay,
} from '@flyward/platform'
import { MaintenanceProgramDetailsTable } from './MaintenanceProgramDetailsTable'
import { type Path, type Control, type FieldValues } from 'react-hook-form'

export interface ChecksDisplay {
  checkId: string
  checkType: CheckType
  utilizationUnit: UtilizationUnits
  utilization: number
  baseCost: number
}

interface IChecksEditableTableProps<TFormData extends FieldValues> {
  checks: ChecksDisplay[] | undefined
  canAddNewRow?: boolean
  formControl: Control<TFormData, unknown>
  isArrayPath?: boolean
}

const tableColumns = <TFormData extends FieldValues>(
  formControl: Control<TFormData, unknown>,
  isArrayPath: boolean,
): Array<TableColumn<ChecksDisplay>> => [
  {
    accessor: 'checkType',
    isIdField: true,
    title: 'Event Type',
    valueGetter: (value) => {
      return CheckTypeDisplay(value as CheckType)
    },
  },
  {
    accessor: 'utilizationUnit',
    title: 'Unit',
    valueGetter: (value) => {
      return UtilizationUnitsDisplay(value as UtilizationUnits)
    },
  },
  {
    accessor: 'utilization',
    title: 'Limit',
    customCell: ({ rowIndex: index }) => {
      const filedName = `${isArrayPath ? `checks.${index}` : 'check'}.matureLimit` as Path<TFormData>
      return (
        <div className="w-18 min-w-18 text-xs">
          <EditableInputCellWithLabelWithLabel inputType={InputType.NaturalNumber} formControl={formControl} fieldName={filedName} />
        </div>
      )
    },
  },
  {
    accessor: 'baseCost',
    title: 'Cost',
    customCell: ({ rowIndex: index }) => {
      const filedName = `${isArrayPath ? `checks.${index}` : 'check'}.baseCost` as Path<TFormData>
      return (
        <div className="w-32 min-w-32 text-xs">
          <EditableInputCellWithLabelWithLabel inputType={InputType.NaturalNumber} formControl={formControl} fieldName={filedName} />
        </div>
      )
    },
  },
]

export const ChecksEditableTable = <TFormData extends FieldValues>({
  checks,
  canAddNewRow = false,
  formControl,
  isArrayPath = true,
}: IChecksEditableTableProps<TFormData>): React.ReactElement<IChecksEditableTableProps<TFormData>> => {
  return <MaintenanceProgramDetailsTable canAddNewRow={canAddNewRow} columns={tableColumns(formControl, isArrayPath)} data={checks} />
}
