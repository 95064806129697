import { ComponentEndOfLeaseContractSchema, type ComponentEndOfLeaseContract } from '../../contract/ComponentEndOfLeaseContract'
import { ComponentEndOfLeaseRateSchema, type ComponentEndOfLeaseRate } from '../../contract/ComponentEndOfLeaseRate'

export interface AuxiliaryPowerUnitEndOfLeaseContract extends ComponentEndOfLeaseContract {
  auxiliaryPowerUnitPREndOfLeaseRate: ComponentEndOfLeaseRate
}

export const AuxiliaryPowerUnitEndOfLeaseContractDtoSchema = ComponentEndOfLeaseContractSchema.extend({
  auxiliaryPowerUnitPREndOfLeaseRate: ComponentEndOfLeaseRateSchema.optional().nullable(),
})
