import { APIRoutes } from '@flyward/platform/constants'
import { http, HttpResponse } from 'msw'
import { type IGetAllLandingGearChecksWithDetailsByAssetIdQueryResponse, type IGetAllLandingGearsByAssetIdQueryResponse } from './types'
import { MaintenanceProgramType } from '@flyward/knowledgeBase/models/enums'

const landingGearsApiMocks = [
  http.get(APIRoutes.AssetsModule.Components.LandingGearsController.GetAll('*'), () => {
    return HttpResponse.json<IGetAllLandingGearsByAssetIdQueryResponse>(
      [
        {
          assignedKBProgramId: 'd4f857ae-c0bc-4e73-96e5-c7c99be7bf12',
          landingGearContract: {
            escalations: {
              maintenanceReserveAgreedEscalationPercentage: 3,
              endOfLeaseAgreedEscalationPercentage: 3,
            },
            landingGearMaintenanceReserveContract: {
              isMaintenanceReserveFundFlushAtEvent: false,

              landingGearMaintenanceReserveRate: {
                checkType: 4,
                utilizationUnit: 1,
                maintenanceReserveCollectionType: 1,
                ratesYear: 2023,
                rateAmount: 3500,
                currentFund: 80000,
              },
              isMaintenanceReserveActive: true,
            },
            landingGearEndOfLeaseContract: {
              landingGearEndOfLeaseRate: {
                typeOfEOLAdjustment: null,
                checkType: 4,
                rateAmount: 3500,
              },
              isEOLApplicable: true,
            },
            minimumReDeliveryConditions: [
              {
                componentContractId: '42f38a3a-73db-4859-8214-5d437b639545',
                minimumReDeliveryConditionType: 1,
                minimumReDeliveryConditionUnit: 3,
                value: 4500,
              },
              {
                componentContractId: '42f38a3a-73db-4859-8214-5d437b639545',
                minimumReDeliveryConditionType: 1,
                minimumReDeliveryConditionUnit: 1,
                value: 24,
              },
            ],
            componentContractDeliverySnapshot: {
              cyclesSinceLastEventAtContractDelivery: 21156,
              totalHoursSinceLastEventAtContractDelivery: 500,
              componentContractId: '42f38a3a-73db-4859-8214-5d437b639545',
              totalHoursSinceNewAtContractDelivery: 30946,
              cyclesSinceNewAtContractDelivery: 21156,
            },
          },
          lessorContribution: {
            isActive: true,
            isUnlimited: false,
            amount: 0,
          },
          componentId: 'cb727512-eb6e-42f1-9a05-164ce076184e',
          componentSerialNumber: '10B12916J',
          componentType: 4,
          componentModel: 'B737-800',

          partNumber: 'NA28008-13',
          manufacturingDetail: {
            manufacturer: 'Messier',
            dom: '2010-10-01',
          },
          assetComponentUtilizationSnapshot: {
            dateOfLastSnapshot: new Date('2023-03-01'),
            totalHoursSinceNew: 30946,
            cyclesSinceNew: 21156,
            componentUtilizationAtEvents: [
              {
                componentUtilizationSnapshotId: 'cb727512-eb6e-42f1-9a05-164ce076184e',
                eventType: 4,
                totalHoursSinceNewAtLastQualifyingEvent: 26632,
                cyclesSinceNewAtLastQualifyingEvent: 18371,
                dateAtLastQualifyingEvent: '2018-10-17',
              },
            ],
          },
          componentContract: {
            componentContractDeliverySnapshot: {
              componentContractId: 'ca2d3fc3-ac58-422d-807a-228f58c16202',
              totalHoursSinceNewAtContractDelivery: 30946,
              cyclesSinceNewAtContractDelivery: 21156,
              totalHoursSinceLastEventAtContractDelivery: null,
              cyclesSinceLastEventAtContractDelivery: null,
            },
          },
          assetId: 'sdaf8a3a-73db-4859-8214-5d437b639545',
        },
      ],
      { status: 200 },
    )
  }),
  http.get(APIRoutes.AssetsModule.Components.LandingGearsController.GetExpandedLandingGears('*'), () => {
    return HttpResponse.json<IGetAllLandingGearChecksWithDetailsByAssetIdQueryResponse>(
      [
        {
          id: 'comp-001',
          type: MaintenanceProgramType.LandingGear,
          serialNumber: 'SN-ABC123456',
          model: 'CFM56-7B24',
          totalHoursSinceNew: 12000,
          cyclesSinceNew: 5000,
          hoursSinceLastPr: 1000,
          cyclesSinceLastPr: 400,
          llpCyclesRemaining: 2000,
          llpDescriptor: 'Fan Disk',
          prFund: 75000,
          llpsFund: 125000,
        },
      ],
      { status: 200 },
    )
  }),
]

export { landingGearsApiMocks }
