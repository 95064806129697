import { validateNullableNaturalNumber, type CheckType } from '@flyward/platform'
import { type TypeOfEOLAdjustment } from '../enums'
import { z } from 'zod'

/**
 * Cloned from from API: Assets.Core.Domain.DTOs.Components.Abstract.EndOfLease.ComponentEndOfLeaseRateDto
 */
export interface ComponentEndOfLeaseRate {
  checkType: CheckType
  ratesYear?: number | null
  rateAmount?: number | null
  typeOfEOLAdjustment: TypeOfEOLAdjustment | null
  eolReferenceUtilization?: number | null
}

export const ComponentEndOfLeaseRateSchema = z.object({
  checkType: z.any().nullable(),
  ratesYear: validateNullableNaturalNumber,
  rateAmount: validateNullableNaturalNumber,
  typeOfEOLAdjustment: z.any().nullable(),
  eolReferenceUtilization: z.any().nullable(),
})
