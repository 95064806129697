import { ComponentMinimumReDeliveryConditionDtoSchema } from '@flyward/platform'
import { ComponentContractDtoSchema, type ComponentContractDto } from '../../contract/ComponentContractDto'
import { EscalationDtoSchema, type EscalationDto } from '../../contract/EscalationDto'
import { AuxiliaryPowerUnitEndOfLeaseContractDtoSchema, type AuxiliaryPowerUnitEndOfLeaseContract } from './AuxiliaryPowerUnitEndOfLeaseContract'
import {
  AuxiliaryPowerUnitMaintenanceReserveContractSchema,
  type AuxiliaryPowerUnitMaintenanceReserveContract,
} from './auxiliaryPowerUnitMaintenanceReserves'
import { type AuxiliaryPowerUnitMinimumReDeliveryCondition } from './auxiliaryPowerUnitMinimumReDeliveryConditions'
import { z } from 'zod'

export interface AuxiliaryPowerUnitContract extends ComponentContractDto {
  auxiliaryPowerUnitId: string
  assetContractId: string
  auxiliaryPowerUnitMaintenanceReserveContract: AuxiliaryPowerUnitMaintenanceReserveContract
  auxiliaryPowerUnitEndOfLeaseContract: AuxiliaryPowerUnitEndOfLeaseContract
  minimumReDeliveryConditions: AuxiliaryPowerUnitMinimumReDeliveryCondition[]
  escalations: EscalationDto
}

export const AuxiliaryPowerUnitContractSchema = ComponentContractDtoSchema.extend({
  escalations: EscalationDtoSchema.optional().nullable(),
  auxiliaryPowerUnitMaintenanceReserveContract: AuxiliaryPowerUnitMaintenanceReserveContractSchema.optional().nullable(),
  auxiliaryPowerUnitEndOfLeaseContract: AuxiliaryPowerUnitEndOfLeaseContractDtoSchema.optional().nullable(),
  minimumReDeliveryConditions: z.array(ComponentMinimumReDeliveryConditionDtoSchema).optional().nullable(),
})
