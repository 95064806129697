import { FullPageLoadingSpinner, SpinnerStateContextProvider, Toaster, useSpinnerState } from '@flyward/platform'
import { RouterProvider, StoreProvider } from './providers'
import { useEffect } from 'react'
import ReactDOM from 'react-dom'
import { isNil } from 'lodash'

const ToasterPortal = () => {
  const portalRoot = document.getElementById('toaster-portal')
  if (isNil(portalRoot)) {
    return <Toaster />
  } else {
    return ReactDOM.createPortal(<Toaster />, portalRoot)
  }
}

export const App = () => {
  const { isShowing } = useSpinnerState()

  useEffect(() => {
    console.info('WebUI version', __WEBUI_VERSION__)
    console.info('WebUI CI current env:', __WEBUI_CI_ENV__, VITE_CI_ENV)
  }, [])

  return (
    <StoreProvider>
      <SpinnerStateContextProvider>
        <div className={'flex h-screen max-h-screen flex-col justify-between overflow-hidden bg-background-light'}>
          <RouterProvider />
          <ToasterPortal />
          <FullPageLoadingSpinner isShowing={isShowing} />
        </div>
      </SpinnerStateContextProvider>
    </StoreProvider>
  )
}
