import { type LandingGearDto } from '@flyward/assets/models'
import { type LessorContributionDto } from '@flyward/assets/models/DTOs/lessorContributionDto'
import { CheckType, CheckTypeDisplay, NumberDisplay, NumberDisplayType, SimpleHeader } from '@flyward/platform'
import { GenericTable } from '@flyward/platform/components/Table/GenericTable'
import { type ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table'

interface ILandingGearLessorContributionsReadonlyTabProps {
  component: LandingGearDto
}

const generateGridColumns = (): Array<ColumnDef<LessorContributionDto>> => {
  const contractualReserveColumns: Array<ColumnDef<LessorContributionDto>> = [
    {
      id: 'checkType',
      accessorKey: 'checkType',
      header: () => <SimpleHeader title="Check Type" className="text-semibold m-0 w-full p-1 text-xs text-black-0" />,
      cell: () => <div className="w-24 min-w-24 text-xs">{CheckTypeDisplay(CheckType.LandingGearOverhaul)}</div>,
    },
    {
      id: 'isActive',
      accessorKey: 'isActive',
      header: () => <SimpleHeader title="Applicable" className="text-semibold m-0 w-full p-1 text-xs text-black-0" />,
      cell: ({ row }) => <div className="flex w-24 min-w-24 text-xs">{row.original?.isActive ? 'Yes' : 'No'}</div>,
    },
    {
      id: 'isUnlimited',
      accessorKey: 'isUnlimited',
      header: () => <SimpleHeader title="Unlimited" className="text-semibold m-0 w-full p-1 text-xs text-black-0" />,
      cell: ({ row }) => {
        if (row.original?.isActive) {
          return <div className="flex w-24 min-w-24 text-xs">{row.original?.isUnlimited ? 'Yes' : 'No'}</div>
        } else {
          return <div className="flex h-5 w-24 min-w-24 bg-black-20"></div>
        }
      },
    },
    {
      id: 'amount',
      accessorKey: 'amount',
      header: () => <SimpleHeader title="Total Amount" className="text-semibold m-0 w-full p-1 text-xs text-black-0" />,
      cell: ({ row }) => {
        if (row.original?.isActive && !row.original?.isUnlimited) {
          return (
            <div className="w-32 min-w-32 text-xs">
              <NumberDisplay displayType={NumberDisplayType.CurrencyRounded} className="w-32 min-w-32" value={row.original?.amount ?? 0} />
            </div>
          )
        } else {
          return <div className="flex h-5 w-32 min-w-32 bg-black-20"></div>
        }
      },
    },
  ]

  return contractualReserveColumns
}

export const LandingGearLessorContributionsReadonlyTab = ({ component }: ILandingGearLessorContributionsReadonlyTabProps) => {
  const lessorContributions: LessorContributionDto[] = [component.lessorContribution]

  const contractualReserveColumns = generateGridColumns()

  const contractualReserveTable = useReactTable<LessorContributionDto>({
    data: lessorContributions,
    columns: contractualReserveColumns,
    getRowCanExpand: () => true,
    getCoreRowModel: getCoreRowModel(),
    enableMultiRowSelection: false,
  })

  return <GenericTable {...contractualReserveTable} />
}
