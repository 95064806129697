import { AssetInformation } from '@flyward/assets'
import {
  ResponsiveForecastingSummaryChart,
  AssetScheduleTable,
  StatisticsTableContainer,
  EventViewPopoverContent,
  formatAssetSchedule,
  type IMaintenanceScheduleDisplay,
} from '@flyward/forecasts'
import {
  ComponentContainer,
  CollapsibleContainer,
  Button,
  ButtonVariant,
  Size,
  IconVariant,
  FullScreenPopover,
  type ComponentMonthlyStatistics,
  ToastVariant,
  useSpinnerState,
  type IdWithIndex,
} from '@flyward/platform'
import { isNil } from 'lodash'
import { ReportFlyForwardParameters } from '../ReportFlyForwardParameters'
import { useCallback, useMemo, useState, useEffect } from 'react'
import { useGetReportItemQuery } from '@flyward/forecasts/store/api/reportItems'
import { toast } from '@flyward/platform/components/_shadcn'

interface IReportBody {
  reportItem: IdWithIndex
}

export const ReportBody = ({ reportItem }: IReportBody) => {
  const [isEventViewPopoverOpen, setIsEventViewPopoverOpen] = useState<boolean>(false)
  const [isStatisticsTableInFullPage, setIsStatisticsTableInFullPage] = useState<boolean>(false)
  const { showSpinner, hideSpinner } = useSpinnerState()
  const reportItemId = reportItem.id

  const onGetReportItemError = useCallback(() => {
    toast({
      variant: ToastVariant.Destructive,
      description: 'Error loading the report',
      icon: IconVariant.Error,
    })
  }, [])

  const {
    data: selectedReport,
    isError: isReportItemError,
    isFetching: isFetchingReportItem,
  } = useGetReportItemQuery(
    { reportItemId },
    {
      skip: reportItem.id === undefined,
    },
  )

  useEffect(() => {
    if (isFetchingReportItem) {
      showSpinner()
    } else {
      hideSpinner()
    }
  }, [hideSpinner, isFetchingReportItem, showSpinner])

  useEffect(() => {
    if (isReportItemError) {
      onGetReportItemError()
    }
  }, [isReportItemError, onGetReportItemError])

  const assetScheduleData: IMaintenanceScheduleDisplay[] = useMemo(
    () => formatAssetSchedule(selectedReport?.outputSchedules, selectedReport?.inputFlyForwardParams.endDate),
    [selectedReport],
  )

  const assetScheduleEvents: IMaintenanceScheduleDisplay[] = useMemo(
    () => formatAssetSchedule(selectedReport?.outputSchedules, selectedReport?.inputFlyForwardParams.endDate, true),
    [selectedReport],
  )

  const componentsMonthlyStatistics: ComponentMonthlyStatistics[] = []
  selectedReport?.assetComponentsMonthlyStatistics.groupedComponentStatistics.forEach((component) => {
    if (component.subComponents !== undefined && component.subComponents.length > 0) {
      componentsMonthlyStatistics.push(...component.subComponents)
    } else {
      componentsMonthlyStatistics.push(component)
    }
  })

  if (isFetchingReportItem) {
    return <span className="min-h-screen"></span>
  }
  return (
    <>
      <ComponentContainer className="p-6">
        <div className="m-0 flex w-full flex-col gap-y-4 p-0">
          {!isNil(selectedReport?.assetDetailsSnapshot) && selectedReport !== undefined ? (
            <AssetInformation asset={selectedReport.assetDetailsSnapshot} showSerialNumber={true} />
          ) : null}
        </div>
      </ComponentContainer>
      <ComponentContainer className="p-6">
        <CollapsibleContainer title="Fly Forward Parameters" containerClassName="w-full gap-y-4">
          {!isNil(selectedReport) && <ReportFlyForwardParameters parameters={selectedReport?.inputFlyForwardParams} />}
        </CollapsibleContainer>
      </ComponentContainer>
      <ComponentContainer className="p-6">
        <CollapsibleContainer title="Chart" containerClassName="w-full gap-y-0">
          <div className="h-96 w-full">
            {!isNil(selectedReport?.assetDetailsSnapshot) && selectedReport !== undefined ? (
              <ResponsiveForecastingSummaryChart assetMonthlyStatistics={selectedReport.assetComponentsMonthlyStatistics.assetMonthlyStatistics} />
            ) : null}
          </div>
        </CollapsibleContainer>
      </ComponentContainer>
      <ComponentContainer className="p-6">
        {selectedReport !== undefined && (
          <CollapsibleContainer
            title="Event Schedule"
            containerClassName="w-full gap-y-0"
            button={
              <Button
                label="Event View"
                variant={ButtonVariant.Secondary}
                size={Size.Medium}
                onClick={() => {
                  setIsEventViewPopoverOpen(true)
                }}
              />
            }
          >
            <AssetScheduleTable
              assetType={selectedReport.assetDetailsSnapshot.assetType}
              reportItemId={selectedReport.reportId}
              index={reportItem.index}
              startDate={selectedReport.assetDetailsSnapshot.dateOfLastTechSpec ?? ''}
              endDate={selectedReport.inputFlyForwardParams.endDate ?? ''}
              schedules={assetScheduleData}
              isLoading={false}
            />
          </CollapsibleContainer>
        )}
      </ComponentContainer>
      <ComponentContainer className="p-6">
        <CollapsibleContainer
          title="Table and month/event map"
          containerClassName="w-full gap-y-0"
          labelClassName="font-semibold text-text-2"
          button={
            <Button
              variant={ButtonVariant.Ghost}
              size={Size.Medium}
              leftIcon={IconVariant.FullScreen}
              onClick={() => {
                setIsStatisticsTableInFullPage(true)
              }}
            />
          }
        >
          {!isNil(selectedReport?.assetDetailsSnapshot) && selectedReport !== undefined ? (
            <StatisticsTableContainer
              allEvents={assetScheduleData}
              assetId={selectedReport.assetDetailsSnapshot.assetId}
              assetType={selectedReport.assetDetailsSnapshot.assetType}
              assetMonthlyStatistics={selectedReport.assetComponentsMonthlyStatistics.assetMonthlyStatistics}
              reportComponentsMonthlyStatistics={selectedReport.assetComponentsMonthlyStatistics}
              startDate={selectedReport.assetDetailsSnapshot.dateOfLastTechSpec ?? ''}
              endDate={selectedReport.inputFlyForwardParams.endDate ?? ''}
            />
          ) : null}
        </CollapsibleContainer>
      </ComponentContainer>
      {!isNil(selectedReport) ? (
        <FullScreenPopover
          content={
            <EventViewPopoverContent
              uniqueEvents={assetScheduleData}
              allEvents={assetScheduleEvents}
              componentsMonthlyStatistics={componentsMonthlyStatistics}
              componentTotalStatisticsAtEvent={selectedReport.assetComponentsMonthlyStatistics.componentTotalStatisticsAtEvent}
            />
          }
          isOpen={isEventViewPopoverOpen}
          onClose={() => {
            setIsEventViewPopoverOpen(false)
          }}
          popoverTitle="Event view"
        />
      ) : null}

      {!isNil(selectedReport?.assetDetailsSnapshot) && selectedReport !== undefined ? (
        <FullScreenPopover
          content={
            <StatisticsTableContainer
              allEvents={assetScheduleData}
              assetId={selectedReport.assetDetailsSnapshot.assetId}
              assetType={selectedReport.assetDetailsSnapshot.assetType}
              assetMonthlyStatistics={selectedReport.assetComponentsMonthlyStatistics.assetMonthlyStatistics}
              reportComponentsMonthlyStatistics={selectedReport.assetComponentsMonthlyStatistics}
              startDate={selectedReport.assetDetailsSnapshot.dateOfLastTechSpec ?? ''}
              endDate={selectedReport.inputFlyForwardParams.endDate ?? ''}
              isFullPage
            />
          }
          isOpen={isStatisticsTableInFullPage}
          onClose={() => {
            setIsStatisticsTableInFullPage(false)
          }}
          popoverTitle="Table and month/event map"
        />
      ) : null}
    </>
  )
}
