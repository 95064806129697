import { ComponentContractDeliverySnapshotSchema, type ComponentContractDeliverySnapshot } from './ComponentContractDeliverySnapshot'
import { z } from 'zod'

/**
 * Cloned from from API: Assets.Core.Domain.DTOs.Components.Abstract.ComponentContractDto
 */
export interface ComponentContractDto {
  componentContractDeliverySnapshot: ComponentContractDeliverySnapshot
}

export const ComponentContractDtoSchema = z.object({
  componentContractDeliverySnapshot: ComponentContractDeliverySnapshotSchema.optional().nullable(),
})
