/* eslint-disable @typescript-eslint/no-misused-promises */
import { type EngineProgramDto, EngineProgramDtoSchema } from '@flyward/knowledgeBase'
import { CnForm, ErrorBoundary, formatAxiosErrorMessage, IconVariant, ToastVariant, useHeaderContent, useNavigationState } from '@flyward/platform'
import { useGetEngineMaintenanceProgramDetailsQuery, useUpdateEngineMaintenanceProgramDetailsMutation } from '@flyward/knowledgeBase/store'
import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { PageContainer } from '../../../layout'
import { isEmpty, isNil } from 'lodash'
import { type SubmitHandler, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { EngineMaintenanceProgramPageHeader } from './EngineMaintenanceProgramPageHeader'
import { EngineMaintenanceProgramPageBody } from './EngineMaintenanceProgramPageBody'
import { toast } from '@flyward/platform/components/_shadcn'

export const EngineMaintenanceProgramPage = () => {
  const { id } = useParams()

  const [isInEditMode, setIsInEditMode] = useState(false)

  const submitRef = useRef<HTMLInputElement>(null)
  const [updateEngine] = useUpdateEngineMaintenanceProgramDetailsMutation()

  const { setTitle, setInputs, setButtons, setHasBackButton } = useHeaderContent()
  const { toggleVisibility } = useNavigationState()

  const { data: engineProgram, isLoading } = useGetEngineMaintenanceProgramDetailsQuery(
    {
      programId: id!,
    },
    {
      skip: isNil(id) || isEmpty(id),
    },
  )

  useEffect(() => {
    toggleVisibility()
    setTitle('Engine Maintenance Program')
    setHasBackButton(true)

    return () => {
      setTitle('')
      setButtons([])
      setHasBackButton(false)
      setInputs([])
      toggleVisibility()
    }
  }, [setButtons, setTitle, setInputs, setHasBackButton, toggleVisibility])

  const form = useForm<EngineProgramDto>({
    defaultValues: engineProgram,
    values: engineProgram,
    resolver: zodResolver(EngineProgramDtoSchema),
    mode: 'all',
    reValidateMode: 'onChange',
  })

  const { handleSubmit: handleEngineUpdate, formState, getValues: getEngineFormValues, control: formControl, trigger: triggerValidations } = form

  const engineFormValues: EngineProgramDto = getEngineFormValues()

  const { isDirty, isValid } = formState

  if (isDirty && !isValid) {
    console.log('KB Engine formState.errors', formState.errors)
  }

  const onUpdateEngine: SubmitHandler<EngineProgramDto> = async (data) => {
    const result = await updateEngine({ programId: id!, engine: data })
    if (!isNil(result.error)) {
      toast({
        variant: ToastVariant.Destructive,
        description: formatAxiosErrorMessage(result.error?.message),
        icon: IconVariant.Error,
      })
    } else {
      toast({
        variant: ToastVariant.Success,
        description: 'Engine updated successfully',
        icon: IconVariant.Success,
      })
    }
  }

  useEffect(() => {
    triggerValidations()
  }, [isValid, triggerValidations])

  const submitForm = () => {
    submitRef.current?.click()
  }

  return (
    <div className="h-max-[calc(100vh-0.5rem)] h-min-[calc(100vh-0.5rem)]  h-[calc(100vh-0.5rem)] overflow-hidden">
      <div className="h-max-[calc(100vh-0.5rem)] h-min-[calc(100vh-0.5rem)]  h-[calc(100vh-0.5rem)] overflow-hidden">
        <EngineMaintenanceProgramPageHeader
          isInEditMode={isInEditMode}
          setIsInEditMode={setIsInEditMode}
          isDirty={isDirty}
          isValid={isValid}
          submitForm={submitForm}
        />
        <CnForm {...form}>
          <PageContainer>
            <form onSubmit={handleEngineUpdate(onUpdateEngine)} className="overflow-auto">
              <ErrorBoundary isLoadingControlled={isLoading}>
                <EngineMaintenanceProgramPageBody engineMaintenanceProgram={engineFormValues} isInEditMode={isInEditMode} formControl={formControl} />
              </ErrorBoundary>
              <input type="submit" className="hidden" ref={submitRef} />
            </form>
          </PageContainer>
        </CnForm>
      </div>
    </div>
  )
}
