import { ActionDialog, Button, ButtonVariant, IconVariant, Input, InputType, ToastVariant, useHeaderContent, useToast } from '@flyward/platform'
import { useSaveReportWithNameMutation } from '@flyward/platform/store'
import { useAppSelector } from '@flyward/platform/store/configureHooks'
import { selectSuccessfulReportId } from '@flyward/platform/store/slices/features/flyForward/flyForwardSelectors'
import { isEmpty } from 'lodash'
import { useEffect, useState } from 'react'

const FlyForwardSave = () => {
  const reportId = useAppSelector(selectSuccessfulReportId)

  const { setCustomElements } = useHeaderContent()
  const { toast } = useToast()

  const [reportName, setReportName] = useState('')
  const [isReportNameValid, setIsReportNameValid] = useState(false)

  const [saveReportWithName] = useSaveReportWithNameMutation()
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false)

  const handleSave = async () => {
    if (reportId === undefined) return
    saveReportWithName({ id: reportId, name: reportName.trim() })
    toast({
      variant: ToastVariant.Success,
      description: 'Your forecast report has been successfully saved!',
      icon: IconVariant.Success,
    })
    setIsSaveModalOpen(false)
  }

  useEffect(() => {
    setCustomElements(
      !isEmpty(reportId)
        ? [
            <Button
              key={`save-btn-${reportId}`}
              variant={ButtonVariant.Primary}
              leftIcon={IconVariant.Save}
              className="mr-1 h-full px-3"
              onClick={() => {
                setIsSaveModalOpen(true)
              }}
            />,
          ]
        : [],
    )
  }, [isReportNameValid, isSaveModalOpen, reportId, reportName, saveReportWithName, setCustomElements, toast])

  return (
    <ActionDialog
      isValid={reportName.trim().length >= 3 && isReportNameValid}
      confirmBtnLabel="Save"
      dialogHeader="Save report"
      key={`save-${reportId}`}
      onConfirm={handleSave}
      onCancel={() => {
        setReportName('')
        setIsSaveModalOpen(false)
      }}
      onSuccess={() => {
        setReportName('')
        setIsSaveModalOpen(false)
      }}
      isOpen={isSaveModalOpen}
      setIsOpen={setIsSaveModalOpen}
      dialogContent={
        <Input
          label="Report name"
          labelClassName="text-semibold text-text-1"
          setValueAfterValidation={(value: string) => {
            setReportName(value.trimStart())
          }}
          type={InputType.Alphanumeric}
          inputClassName="w-full"
          setIsValid={setIsReportNameValid}
        />
      }
    />
  )
}

export { FlyForwardSave }
