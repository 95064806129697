import { CheckTypeDisplay, EditableInputCellWithLabelWithLabel, InputType, isArrayEmptyOrNull, TextCellWithLabel } from '@flyward/platform'
import { Fragment } from 'react'
import { type ComponentEndOfLeaseRate } from '../../../../../models'
import { cloneDeep } from 'lodash'
import { type Control, type Path, type FieldValues } from 'react-hook-form'

interface IEndOfLeaseEditableRatesProps<TFormData extends FieldValues> {
  rates: ComponentEndOfLeaseRate[]
  isEolApplicable: boolean
  formControl: Control<TFormData, unknown>
  fieldPath: Path<TFormData>
  isArrayPath?: boolean
}

export const EndOfLeaseEditableRates = <TFormData extends FieldValues>({
  rates,
  isEolApplicable,
  formControl,
  fieldPath,
  isArrayPath = true,
}: IEndOfLeaseEditableRatesProps<TFormData>): React.ReactElement<IEndOfLeaseEditableRatesProps<TFormData>> => {
  if (isArrayEmptyOrNull(rates)) {
    return (
      <Fragment>
        <TextCellWithLabel className="basis-1/6" label={`EOL Rate`} preventInfo={!isEolApplicable} />
      </Fragment>
    )
  }

  cloneDeep(rates).sort((a, b) => Number(a.checkType) - Number(b.checkType))

  const eolRates = rates.map((eolRate: ComponentEndOfLeaseRate, index) => {
    const rateAmountPath: Path<TFormData> = `${fieldPath}${isArrayPath ? `.${index}` : ''}.rateAmount` as Path<TFormData>
    return (
      <Fragment key={`${index} - ${eolRate?.checkType}`}>
        <EditableInputCellWithLabelWithLabel
          inputType={InputType.Decimal}
          className="basis-1/6"
          label={`EOL Rates ${CheckTypeDisplay(eolRate?.checkType)}`}
          formControl={formControl}
          fieldName={rateAmountPath}
          preventInfo={!isEolApplicable}
        />
      </Fragment>
    )
  })

  return <>{eolRates}</>
}
