import { MaintenanceProgramsGrid } from '@flyward/knowledgeBase'
import { PageContainer } from '@flyward/main-app/layout'
import {
  ButtonVariant,
  ComponentType,
  ComponentContainer,
  ErrorBoundary,
  IconVariant,
  Size,
  useHeaderContent,
  useSpinnerState,
  type IButtonProps,
} from '@flyward/platform'
import { OutletTabs } from '@flyward/platform/components/Tabs/OutletTabs/OutletTabs'
import {
  useGetAllAirframeMaintenanceProgramsQuery,
  useGetAllAuxiliaryPowerUnitMaintenanceProgramsQuery,
  useGetAllEngineMaintenanceProgramsQuery,
  useGetAllLandingGearMaintenanceProgramsQuery,
} from '@flyward/platform/store'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

type AvailableTabs = 'Engine' | 'Airframe' | 'Landing Gear' | 'APU'

const ActiveTabToPath: Record<AvailableTabs, string> = {
  Engine: 'engine-maintenance-program',
  Airframe: 'airframe-maintenance-program',
  APU: 'auxiliary-power-unit-maintenance-program',
  'Landing Gear': 'landing-gears-maintenance-program',
}

const PathToActiveTab: Record<string, AvailableTabs> = {
  'engine-maintenance-program': 'Engine',
  'airframe-maintenance-program': 'Airframe',
  'auxiliary-power-unit-maintenance-program': 'APU',
  'landing-gears-maintenance-program': 'Landing Gear',
}

export const KnowledgeBasePage = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { showSpinner, hideSpinner } = useSpinnerState()
  const { setTitle, setButtons } = useHeaderContent()
  const [currentTab, setCurrentTab] = useState<AvailableTabs>(PathToActiveTab[location.pathname.substring(location.pathname.lastIndexOf('/') + 1)])

  const { data: enginePrograms, isLoading: isLoadingEnginePrograms } = useGetAllEngineMaintenanceProgramsQuery(true)
  const { data: airframePrograms, isLoading: isLoadingAirframePrograms } = useGetAllAirframeMaintenanceProgramsQuery(true)
  const { data: landingGearPrograms, isLoading: isLoadingLandingGearPrograms } = useGetAllLandingGearMaintenanceProgramsQuery(true)
  const { data: auxiliaryPowerUnitMaintenancePrograms, isLoading: isLoadingAuxiliaryPowerUnitMaintenancePrograms } =
    useGetAllAuxiliaryPowerUnitMaintenanceProgramsQuery(true)

  const onTabChanged = (activeTab: string) => {
    navigate(`/knowledge-base/${ActiveTabToPath[activeTab as AvailableTabs]}`)
    setCurrentTab(activeTab as AvailableTabs)
  }

  useEffect(() => {
    const buttonProps: IButtonProps[] = [
      {
        variant: ButtonVariant.Primary,
        size: Size.Medium,
        label: 'Fly Forward',
        leftIcon: IconVariant.FlightTakeOff,
        onClick: () => {
          navigate('/flyforward')
        },
      },
    ]

    setTitle('Knowledge Base')
    setButtons(buttonProps)

    return () => {
      setTitle('')
      setButtons([])
    }
  }, [navigate, setButtons, setTitle])

  const tabs = {
    Engine: {
      content: (
        <ErrorBoundary>
          <MaintenanceProgramsGrid programs={enginePrograms ?? []} componentType={ComponentType.EngineUnit} detailsRoute={location.pathname} />
        </ErrorBoundary>
      ),
    },
    Airframe: {
      content: (
        <ErrorBoundary>
          <MaintenanceProgramsGrid programs={airframePrograms ?? []} componentType={ComponentType.Airframe} detailsRoute={location.pathname} />
        </ErrorBoundary>
      ),
    },
    'Landing Gear': {
      content: (
        <ErrorBoundary>
          <MaintenanceProgramsGrid programs={landingGearPrograms ?? []} componentType={ComponentType.LandingGear} detailsRoute={location.pathname} />
        </ErrorBoundary>
      ),
    },
    APU: {
      content: (
        <ErrorBoundary>
          <MaintenanceProgramsGrid
            programs={auxiliaryPowerUnitMaintenancePrograms ?? []}
            componentType={ComponentType.AuxiliaryPowerUnit}
            detailsRoute={location.pathname}
          />
        </ErrorBoundary>
      ),
    },
  }

  isLoadingAirframePrograms || isLoadingAuxiliaryPowerUnitMaintenancePrograms || isLoadingEnginePrograms || isLoadingLandingGearPrograms
    ? showSpinner()
    : hideSpinner()

  return (
    <PageContainer>
      <ComponentContainer className="h-full w-full !items-start !justify-start py-6">
        <OutletTabs tabs={tabs} activeTab={currentTab} onTabChanged={onTabChanged} />
      </ComponentContainer>
    </PageContainer>
  )
}
