import { axiosInstance } from '@flyward/platform/services'
import { type BaseQueryFn, createApi } from '@reduxjs/toolkit/query/react'
import { AxiosError, type AxiosRequestConfig } from 'axios'

const axiosBaseQuery =
  (
    { baseUrl }: { baseUrl: string } = { baseUrl: '' },
  ): BaseQueryFn<
    {
      url: string
      method?: AxiosRequestConfig['method']
      data?: AxiosRequestConfig['data']
      params?: AxiosRequestConfig['params']
      headers?: AxiosRequestConfig['headers']
    },
    unknown,
    AxiosError
  > =>
  async ({ url, method, data, params, headers }) => {
    try {
      const result = await axiosInstance({
        url: baseUrl + url,
        method,
        data,
        params,
        headers,
      })
      return { data: result.data }
    } catch (axiosError) {
      console.error('Axios Error:', axiosError)
      const axiosTypedError = axiosError as AxiosError
      const errorResponse = JSON.stringify(axiosTypedError.response)
      throw new AxiosError(errorResponse, axiosTypedError.code, axiosTypedError.config, axiosTypedError.request, axiosTypedError.response)
    }
  }

const flywardApi = createApi({
  reducerPath: 'flywardApiSlice',
  tagTypes: [
    'apiData',
    'airframes',
    'airframeMaintenancePrograms',
    'aircrafts',
    'assets',
    'assetsFilters',
    'auxiliaryPowerUnits',
    'auxiliaryPowerUnitMaintenancePrograms',
    'assetContracts',
    'averageUtilisations',
    'flyForwardDefaultParameters',
    'componentChecks',
    'engines',
    'engineMaintenancePrograms',
    'landingGears',
    'landingGearMaintenancePrograms',
    'loggedUser',
    'reportsSearch',
    'reports',
    'reportItems',
    'standaloneEngines',
    'users',
    'tenants',
    'userNames',
  ],
  baseQuery: axiosBaseQuery(),
  endpoints: () => ({}),
})

export { flywardApi }
