import { z } from 'zod'

/**
 * Cloned from from API: Assets.Core.Domain.DTOs.Components.Abstract.MaintenanceReserve.ComponentMaintenanceReserveContractDto
 */
export interface ComponentMaintenanceReserveContractDto {
  isMaintenanceReserveFundFlushAtEvent: boolean | null
  isMaintenanceReserveActive: boolean
}

export const ComponentMaintenanceReserveContractSchema = z.object({
  isMaintenanceReserveFundFlushAtEvent: z.any().nullable(),
  isMaintenanceReserveActive: z.any().nullable(),
})
