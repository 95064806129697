import { type AircraftEngineDto, type ContractualFlightHoursToFlightCyclesPerformanceRestorationReserveDto } from '@flyward/assets/models'
import { getCoreRowModel, useReactTable } from '@tanstack/react-table'
import { contractualReserveEditableColumns } from './constants'
import { GenericTable } from '@flyward/platform/components/Table/GenericTable'
import { type Control, type FormState, type UseFormRegister, type UseFormSetValue } from 'react-hook-form'

interface IContractualReserveEditableTabProps {
  performanceRestorationReserves: ContractualFlightHoursToFlightCyclesPerformanceRestorationReserveDto[]
  registerFormField: UseFormRegister<AircraftEngineDto>
  setFormValue: UseFormSetValue<AircraftEngineDto>
  formControl: Control<AircraftEngineDto, unknown>
  formState: FormState<AircraftEngineDto>
}

const ContractualReserveEditableTab = ({ performanceRestorationReserves, formControl }: IContractualReserveEditableTabProps) => {
  const contractualReserveTable = useReactTable<ContractualFlightHoursToFlightCyclesPerformanceRestorationReserveDto>({
    data: performanceRestorationReserves,
    columns: contractualReserveEditableColumns(formControl),
    getRowCanExpand: () => true,
    getCoreRowModel: getCoreRowModel(),
    enableMultiRowSelection: false,
  })

  return <GenericTable {...contractualReserveTable} />
}

export { ContractualReserveEditableTab }
export type { IContractualReserveEditableTabProps }
