import { ComponentMinimumReDeliveryConditionDtoSchema, type ComponentMinimumReDeliveryConditionDto } from '@flyward/platform/models'
import { ComponentContractDtoSchema, type ComponentContractDto } from '../../contract/ComponentContractDto'
import { EscalationDtoSchema, type EscalationDto } from '../../contract/EscalationDto'
import { AirframeEndOfLeaseContractDtoSchema, type AirframeEndOfLeaseContractDto } from './AirframeEndOfLeaseContract'
import { AirframeMaintenanceReserveContractSchema, type AirframeMaintenanceReserveContract } from './AirframeMaintenanceReserveContract'
import { z } from 'zod'

/**
 * Cloned from API: Assets.Core.Domain.DTOs.Components.Airframe.AirframeContractDto
 */
export interface AirframeContractDto extends ComponentContractDto {
  escalations: EscalationDto
  airframeMaintenanceReserveContract: AirframeMaintenanceReserveContract
  airframeEndOfLeaseContract: AirframeEndOfLeaseContractDto
  minimumReDeliveryConditions: ComponentMinimumReDeliveryConditionDto[]
}

export const AirframeContractSchema = ComponentContractDtoSchema.extend({
  escalations: EscalationDtoSchema,
  airframeMaintenanceReserveContract: AirframeMaintenanceReserveContractSchema,
  airframeEndOfLeaseContract: AirframeEndOfLeaseContractDtoSchema,
  minimumReDeliveryConditions: z.array(ComponentMinimumReDeliveryConditionDtoSchema),
})
