import { flexRender, type Row } from '@tanstack/react-table'
import { type GroupedComponentMonthlyStatistics, type MonthlyStatistics, cn, type ComponentMonthlyStatistics } from '@flyward/platform'
import { Fragment } from 'react'
import { getCommonPinningStyles } from '../customStatisticsColumns'

export interface CustomTableExpandableRowProps {
  propertyName: keyof MonthlyStatistics
  row: Row<GroupedComponentMonthlyStatistics>
  expandedComponent: ({ data, propertyName }: { data: ComponentMonthlyStatistics[]; propertyName: keyof MonthlyStatistics }) => JSX.Element
}

export const CustomLLPTableExpandableRow = ({ propertyName, row: parentRow, expandedComponent }: CustomTableExpandableRowProps) => {
  const ExpandedComponent = expandedComponent

  const rows = parentRow.original.subComponents ?? []
  const row = rows[0]
  const componentId = row.forecastedCheck.componentId
  const checkType = row.forecastedCheck.checkType

  return (
    <Fragment key={`${componentId}-${checkType}-expander-container`}>
      <tr className={cn(`m-0 items-center gap-x-10 px-4 py-2 text-xs`, parentRow.getIsExpanded() ? 'bg-primary' : 'bg-black-0')}>
        {parentRow.getVisibleCells().map((cell, index) => {
          const { column } = cell
          const textColor = index === 0 ? 'text-text-1' : ' text-text-2'
          return (
            <td
              key={cell.id}
              className={cn(
                'items-center border-b border-black-10 py-1.5 pl-4 pr-2 text-xs',
                `${index === 0 ? 'sticky left-0 z-10 w-40 max-w-40 font-semibold' : 'w-32 max-w-32 justify-end text-right'}`,
                parentRow.getIsExpanded() ? 'bg-primary text-text-4' : `bg-black-0 ${textColor}`,
              )}
              style={{ ...getCommonPinningStyles(column) }}
            >
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </td>
          )
        })}
      </tr>
      {parentRow.getIsExpanded() && (
        <tr>
          <td colSpan={parentRow.getVisibleCells().length} className="p-0">
            <ExpandedComponent data={rows} propertyName={propertyName} />
          </td>
        </tr>
      )}
    </Fragment>
  )
}
