import { NumberDisplay, NumberDisplayType, type TableColumn } from '@flyward/platform'
import { type FlightHoursFlightCyclesRatio } from '../../../../models'
import { MaintenanceProgramDetailsTable } from '../../MaintenanceProgramDetailsTable'

interface ICyclesHoursRatioTableReadOnlyProps {
  flightHoursFlightCyclesRatios: FlightHoursFlightCyclesRatio[] | undefined
}

const tableColumns: Array<TableColumn<FlightHoursFlightCyclesRatio>> = [
  {
    accessor: 'id',
    isIdField: true,
    title: '',
    isHidden: true,
  },
  {
    accessor: 'ratio',
    title: 'Ratio',
    valueGetter: (value) => <NumberDisplay value={value} displayType={NumberDisplayType.Decimal} />,
  },
  {
    accessor: 'firstRunLimit',
    title: 'First run limit',
    valueGetter: (value) => <NumberDisplay value={value} />,
  },
  {
    accessor: 'matureLimit',
    title: 'Mature limit',
    valueGetter: (value) => <NumberDisplay value={value} />,
  },
]

export const FlightHoursFlightCyclesRatioReadOnlyTable = ({
  flightHoursFlightCyclesRatios,
}: ICyclesHoursRatioTableReadOnlyProps): React.ReactElement<ICyclesHoursRatioTableReadOnlyProps> => {
  return <MaintenanceProgramDetailsTable columns={tableColumns} data={flightHoursFlightCyclesRatios} />
}
