import {
  NumberDisplay,
  type UtilizationUnits,
  UtilizationUnitsDisplay,
  type TableColumn,
  NumberDisplayType,
  TableRow,
  TableCell,
  EditableInputCellWithLabelWithLabel,
  InputType,
} from '@flyward/platform'
import { MaintenanceProgramDetailsTable } from '../../MaintenanceProgramDetailsTable'
import { type llpCheckDto, type EngineProgramDto } from '../../../../models'
import { cloneDeep } from 'lodash'
import { type Path, type Control, type FieldValues } from 'react-hook-form'

interface EngineLlpCheckEditableDisplay {
  programId: string
  componentModel?: string
  componentModule?: string
  unit?: UtilizationUnits
  limit?: number
  cost?: number
  costPerCycle?: number
}

interface IEngineLifeLimitedPartsTableProps<TFormData extends FieldValues> {
  llpChecks: llpCheckDto[] | undefined
  engineProgram: EngineProgramDto | undefined
  formControl: Control<TFormData, unknown>
}

const llpTableColumns = <TFormData extends FieldValues>(
  formControl: Control<TFormData, unknown>,
): Array<TableColumn<EngineLlpCheckEditableDisplay>> => [
  {
    accessor: 'programId',
    title: '',
    isIdField: true,
    isHidden: true,
  },
  {
    accessor: 'componentModel',
    title: 'Description',
    customCell: ({ rowIndex: index }) => {
      const filedName = `llpChecks.${index}.componentModel` as Path<TFormData>
      return (
        <div className="w-48 min-w-48 text-xs">
          <EditableInputCellWithLabelWithLabel formControl={formControl} fieldName={filedName} />
        </div>
      )
    },
  },
  {
    accessor: 'componentModule',
    title: 'Module',
    customCell: ({ rowIndex: index }) => {
      const filedName = `llpChecks.${index}.componentModule` as Path<TFormData>
      return (
        <div className="w-24 min-w-28 text-xs">
          <EditableInputCellWithLabelWithLabel formControl={formControl} fieldName={filedName} />
        </div>
      )
    },
  },
  {
    accessor: 'unit',
    title: 'Unit',
    valueGetter: (value) => UtilizationUnitsDisplay(value as UtilizationUnits),
  },
  {
    accessor: 'limit',
    title: 'Limit',
    customCell: ({ rowIndex: index }) => {
      const filedName = `llpChecks.${index}.matureLimit` as Path<TFormData>
      return (
        <div className="w-24 min-w-28 text-xs">
          <EditableInputCellWithLabelWithLabel inputType={InputType.NaturalNumber} formControl={formControl} fieldName={filedName} />
        </div>
      )
    },
  },
  {
    accessor: 'cost',
    title: 'CLP',
    customCell: ({ rowIndex: index }) => {
      const filedName = `llpChecks.${index}.baseCost` as Path<TFormData>
      return (
        <div className="w-24 min-w-28 text-xs">
          <EditableInputCellWithLabelWithLabel inputType={InputType.NaturalNumber} formControl={formControl} fieldName={filedName} />
        </div>
      )
    },
  },
  {
    accessor: 'costPerCycle',
    title: 'Cost/Cycle',
    valueGetter: (value) => <NumberDisplay displayType={NumberDisplayType.Currency} currencyDecimals={2} value={value} />,
  },
]

export const EngineLifeLimitedPartsEditableTable = <TFormData extends FieldValues>({
  llpChecks,
  engineProgram,
  formControl,
}: IEngineLifeLimitedPartsTableProps<TFormData>): React.ReactElement<IEngineLifeLimitedPartsTableProps<TFormData>> => {
  const llpTableData: EngineLlpCheckEditableDisplay[] | undefined =
    llpChecks !== undefined
      ? cloneDeep(llpChecks).map((t) => ({
          programId: t.id,
          componentModel: t.componentModel,
          componentModule: t.componentModule,
          unit: t?.utilizationUnit,
          limit: t?.matureLimit,
          cost: t?.baseCost,
          costPerCycle: t?.costPerCycle,
        }))
      : undefined

  const totalLLPsCost = engineProgram?.totalLLPsCost
  const totalLLPsCostPerCycle = engineProgram?.totalLLPsCostPerCycle

  const summaryRow = (
    <TableRow className="font-semibold">
      <TableCell className="text-1 border border-black-20 bg-primary-dark-3 p-px" colSpan={1}>
        Total
      </TableCell>
      <TableCell className="border border-black-20 bg-primary-dark-3 p-px text-text-1" colSpan={3}></TableCell>
      <TableCell className="border border-black-20 bg-primary-dark-3 p-px text-text-1">
        <NumberDisplay displayType={NumberDisplayType.CurrencyRounded} value={totalLLPsCost} />
      </TableCell>
      <TableCell className="border border-black-20 bg-primary-dark-3 p-px text-text-1">
        <NumberDisplay displayType={NumberDisplayType.Currency} value={totalLLPsCostPerCycle} />
      </TableCell>
    </TableRow>
  )

  return <MaintenanceProgramDetailsTable columns={llpTableColumns(formControl)} data={llpTableData} summaryRow={summaryRow} />
}
