import { APIRoutes } from '@flyward/platform/constants'
import { http, HttpResponse } from 'msw'
import { type IGetAllAuxiliaryPowerUnitChecksWithDetailsByAssetIdQueryResponse, type IGetAllAuxiliaryPowerUnitsByAssetIdQueryResponse } from './types'
import { MaintenanceProgramType } from '@flyward/knowledgeBase/models/enums'

const auxiliaryPowerUnitsApiMocks = [
  http.get(APIRoutes.AssetsModule.Components.AuxiliaryPowerUnitsController.GetAll('*'), () => {
    return HttpResponse.json<IGetAllAuxiliaryPowerUnitsByAssetIdQueryResponse>(
      [
        {
          assignedKBProgramId: 'd4f857ae-c0bc-4e73-96e5-c7c99be7bf12',
          auxiliaryPowerUnitContract: {
            auxiliaryPowerUnitId: 'cba502eb-533a-47f1-a8a6-5066994f4d5f',
            escalations: {
              maintenanceReserveAgreedEscalationPercentage: 3,
              endOfLeaseAgreedEscalationPercentage: 3,
            },
            assetContractId: 'b7964078-9102-4e8f-a61b-ebffd7e25e9d',
            auxiliaryPowerUnitMaintenanceReserveContract: {
              isMaintenanceReserveFundFlushAtEvent: false,
              auxiliaryPowerUnitPRMaintenanceReserveRate: {
                auxiliaryPowerUnitMaintenanceReserveId: 'feff53d5-0f59-4c25-a07c-b60b5144347e',
                checkType: 5,
                utilizationUnit: 4,
                maintenanceReserveCollectionType: 1,
                ratesYear: 2023,
                rateAmount: 32,
                currentFund: 175000,
              },
              isMaintenanceReserveActive: true,
            },
            auxiliaryPowerUnitEndOfLeaseContract: {
              auxiliaryPowerUnitPREndOfLeaseRate: {
                typeOfEOLAdjustment: 1,
                checkType: 5,
                rateAmount: 32,
              },
              isEOLApplicable: true,
            },
            minimumReDeliveryConditions: [
              {
                componentContractId: '4e413e7b-acb0-439d-884f-f3259cf0da2f',
                minimumReDeliveryConditionType: 2,
                minimumReDeliveryConditionUnit: 2,
                value: 3000,
              },
            ],
            componentContractDeliverySnapshot: {
              cyclesSinceLastEventAtContractDelivery: 500,
              totalHoursSinceLastEventAtContractDelivery: 232,
              componentContractId: '4e413e7b-acb0-439d-884f-f3259cf0da2f',
              totalHoursSinceNewAtContractDelivery: 24009,
              cyclesSinceNewAtContractDelivery: 17499,
            },
          },
          lessorContribution: {
            isActive: true,
            isUnlimited: false,
            amount: 0,
          },
          componentId: 'cba502eb-533a-47f1-a8a6-5066994f4d5f',
          componentSerialNumber: '3072',
          componentType: 3,
          componentModel: 'APS 3200',
          manufacturingDetail: {
            manufacturer: 'Hamilton Sundstrand International S.A.',
            dom: '2018-10-01',
          },
          assetComponentUtilizationSnapshot: {
            dateOfLastSnapshot: new Date('2023-03-01'),
            totalHoursSinceNew: 17499,
            cyclesSinceNew: 24009,
            componentUtilizationAtEvents: [
              {
                componentUtilizationSnapshotId: 'cba502eb-533a-47f1-a8a6-5066994f4d5f',
                eventType: 5,
                totalHoursSinceNewAtLastQualifyingEvent: 20000,
                cyclesSinceNewAtLastQualifyingEvent: 23973,
                dateAtLastQualifyingEvent: '2020-09-14',
              },
            ],
          },
          componentContract: {
            componentContractDeliverySnapshot: {
              componentContractId: 'ca2d3fc3-ac58-422d-807a-228f58c16202',
              totalHoursSinceNewAtContractDelivery: 30946,
              cyclesSinceNewAtContractDelivery: 21156,
              totalHoursSinceLastEventAtContractDelivery: null,
              cyclesSinceLastEventAtContractDelivery: null,
            },
          },
          assetId: 'sdaf8a3a-73db-4859-8214-5d437b639545',
          partNumber: 'NA28008-13',
        },
      ],
      { status: 200 },
    )
  }),
  http.get(APIRoutes.AssetsModule.Components.AuxiliaryPowerUnitsController.GetExpandedAuxiliaryPowerUnits('*'), () => {
    return HttpResponse.json<IGetAllAuxiliaryPowerUnitChecksWithDetailsByAssetIdQueryResponse>(
      [
        {
          id: 'comp-001',
          type: MaintenanceProgramType.AuxiliaryPowerUnit,
          serialNumber: 'SN-ABC123456',
          model: 'CFM56-7B24',
          totalHoursSinceNew: 12000,
          cyclesSinceNew: 5000,
          hoursSinceLastPr: 1000,
          cyclesSinceLastPr: 400,
          llpCyclesRemaining: 2000,
          llpDescriptor: 'Fan Disk',
          prFund: 75000,
          llpsFund: 125000,
        },
      ],
      { status: 200 },
    )
  }),
]

export { auxiliaryPowerUnitsApiMocks }
